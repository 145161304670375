import React from 'react'
import withContainer from '../../HOCs/withContainer'
import Typography from '@material-ui/core/Typography'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import Grid from '@material-ui/core/Grid'
import { useParams } from 'react-router-dom'
import Apple from '@material-ui/icons/Apple'
import GooglePlay from '@material-ui/icons/Shop'
import Home from '@material-ui/icons/Home'

const Success = (props) => {
  const { type, device } = useParams()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '90vh' }}
    >
      <Grid item>
        <Typography variant="h6" color="inherit" align="center">
          <CheckCircleOutlineIcon
            style={{
              color: '#4caf50',
              fontSize: 144
            }}
            // style={{ fontSize:'40px' }}
            fontSize="large"
          ></CheckCircleOutlineIcon>
          <br></br>
          <div
            style={{
              color: '#444',
              fontSize: 20,
              fontWeight: 400
            }}
          >
            {type && type === 'approved'
              ? [
                  'Randevunuz onaylanmıştır.',
                  <br key="0" />,
                  'Randevu bilgilerinizi Randevular/Randevularım menüsünden inceleyebilirsiniz.'
                ]
              : type && type === 'pending'
              ? [
                  'Randevu talebiniz salon yöneticisi ve ilgili personele onay/red işlemi için iletilmiştir.',
                  <br key="1" />,
                  'Randevu talebinizle ilgili geri bildirim almak için, bildirimlerinizi açmanız tavsiye edilmektedir.',
                  <br key="2" />,
                  'Randevu durumunuzu Randevularım menüsünden takip edebilirsiniz.'
                ]
              : 'Randevunuz başarıyla kaydedilmiştir.'}
            {device && device === 'web'
              ? [
                  <br key="0" />,
                  <br key="1" />,
                  'Uygulamamızı aşağıdaki bağlantılardan indirebilirsiniz.',
                  <br key="2" />,
                  <br key="3" />,
                  <a
                    style={{
                      background: '#00c9db',
                      borderRadius: 40,
                      lineHeight: '40px',
                      padding: '10px 20px',
                      textDecoration: 'none',
                      color: 'white',
                      margin: '5px'
                    }}
                    href="https://apps.apple.com/tr/app/kuaf%C3%B6r%C3%BCm-yan%C4%B1mda/id1516248277?l=tr"
                  >
                    <Apple
                      style={{
                        height: '40px',
                        verticalAlign: 'top',
                        paddingBottom: '5px',
                        marginRight: '5px'
                      }}
                    />
                    <span>Apple Store</span>
                  </a>,
                  <a
                    style={{
                      background: '#00c9db',
                      borderRadius: 40,
                      lineHeight: '40px',
                      padding: '10px 20px',
                      textDecoration: 'none',
                      color: 'white',
                      margin: '5px'
                    }}
                    href="https://play.google.com/store/apps/details?id=com.tr.kuaforum_yanimda"
                  >
                    <GooglePlay
                      style={{
                        height: '40px',
                        verticalAlign: 'top',
                        paddingBottom: '5px',
                        marginRight: '5px'
                      }}
                    />
                    <span>Google Play</span>
                  </a>,
                  <br key="4" />,
                  <br key="5" />,
                  <a
                    style={{
                      background: 'transparent',
                      borderRadius: 40,
                      lineHeight: '40px',
                      padding: '10px 20px',
                      textDecoration: 'none',
                      color: 'white',
                      margin: '5px',
                      border: '0.125rem solid #00c9db',
                      color: '#00c9db'
                    }}
                    href="https://kuaforumyanimda.com"
                  >
                    <Home
                      style={{
                        height: '40px',
                        verticalAlign: 'top',
                        paddingBottom: '5px',
                        marginRight: '5px'
                      }}
                    />
                    <span>Anasayfaya Dön</span>
                  </a>
                ]
              : ''}
          </div>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withContainer(Success)
