import React from 'react'
import ReactDOM from 'react-dom'
import './Assets/scss/app.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/tr'
import moment from 'moment'

moment().locale('tr')

// prod
const firebaseConfig = {
  apiKey: 'AIzaSyD-Ur7ydrKDgMR1V2JrBKorluLyzxfxwgY',
  authDomain: 'kuaforumyanimda-732b8.firebaseapp.com',
  databaseURL: 'https://kuaforumyanimda-732b8.firebaseio.com',
  projectId: 'kuaforumyanimda-732b8',
  storageBucket: 'kuaforumyanimda-732b8.appspot.com',
  messagingSenderId: '1056245821841',
  appId: '1:1056245821841:web:8e3d0d6f43a1abac522a30',
  measurementId: 'G-JC6N1FG4E3',
}

//pre
// const firebaseConfig = {
//   apiKey: 'AIzaSyAXOlAewWYpKN8xPkRlNx5JLsoHDHE7jM8',
//   authDomain: 'kuaforumyanimdapre.firebaseapp.com',
//   databaseURL: 'https://kuaforumyanimdapre.firebaseio.com',
//   projectId: 'kuaforumyanimdapre',
//   storageBucket: 'kuaforumyanimdapre.appspot.com',
//   messagingSenderId: '699899286270',
//   appId: '1:699899286270:web:34b51367d852a065838baf',
//   measurementId: 'G-BBZSES4V2D',
// }

//test
// const firebaseConfig = {
//   apiKey: 'AIzaSyCzazmBgN_KuH_g2GrMqCY4tQA0Zp8ZMk0',
//   authDomain: 'kuaforumyanimdatest-259a1.firebaseapp.com',
//   databaseURL: 'https://kuaforumyanimdatest-259a1.firebaseio.com',
//   projectId: 'kuaforumyanimdatest-259a1',
//   storageBucket: 'kuaforumyanimdatest-259a1.appspot.com',
//   messagingSenderId: '334975224164',
//   appId: '1:334975224164:web:58c9caf80ba1ec40975271',
//   measurementId: 'G-KBJ1VD6L4G',
// }

window.firebase.initializeApp(firebaseConfig)

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <App />
  </MuiPickersUtilsProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
