import React from "react";
import withContainer from "../../HOCs/withContainer";

const Unauthorized = props => {
  return (
    <>
      Lütfen giriş yapınız
    </>
  )
}

export default withContainer(Unauthorized)
