import React from 'react'
import Typography from '@material-ui/core/Typography'
import * as _ from 'lodash'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
} from '@material-ui/core'

const OzetAccordion = ({
  collapse,
  goToCollapse,
  setCollapse,

  selectedDate,
  selectedSalon,
  selectedHour,
  selectedServices,
  selectedStaff,
  device,
  salonId,
  userId,
  customerData: customer,

  getAppointments,
  getOnlyAppointments,
  getHours,
}) => {
  const db = window.firebase.firestore().collection('kuaforumDB')
  const [isApproved, setIsApproved] = React.useState(false)

  const lastCheck = (callback) => {
    getOnlyAppointments((randevuList) => {
      callback(
        getHours(randevuList)
          .map((e) => e.filter((el) => el.status).map((v) => v.date))
          .flat()
          .some((e) => e.unix() === selectedHour.unix())
      )
    })
  }

  const isFieldsOK = (obj) => {
    if (!Object.values(obj).every((val) => val !== undefined && val !== null)) {
      return false
    }
    return true
  }

  const addRandevu = (ref, onay, totalPrice, totalDuration) =>
    ref
      .doc(`${selectedDate.clone().startOf('month').unix()}`)
      .collection('get')
      .add({
        hizmetler: Object.keys(selectedServices),
        favori: false,
        mID: userId,
        mAd: `${customer.firstname} ${customer.lastname}`,
        mTel: customer.phone,
        pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
        sAd: selectedSalon.salonAd,
        pID: selectedStaff.id,
        randevuTarih: selectedHour.toDate(),
        fiyat: totalPrice,
        sure: totalDuration,
        randevuRol: 'Musteri',
        randevuTur: 'Normal',
        isWeb: device === 'web',
        onay,
      })

  const approve = async () => {
    if (isApproved) return
    setIsApproved(true)
    //props.setLoading(true)
    lastCheck(async (isValidDate) => {
      if (!isValidDate) {
        alert(
          'Randevu tarihi artık geçerli değil. Lütfen geçerli bir randevu tarihi seçin.'
        )
        getAppointments()
        //props.setLoading(false)
        return
      }

      const totalDuration = _.sumBy(Object.values(selectedServices), (o) =>
        parseInt(o.islemSuresi)
      )
      const totalPrice = _.sumBy(Object.values(selectedServices), (o) =>
        parseInt(o.islemUcreti)
      )

      // test if all is ok
      if (
        !isFieldsOK({
          mID: userId,
          mAd: `${customer.firstname} ${customer.lastname}`,
          mTel: customer.phone,
          pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
          sAd: selectedSalon.salonAd,
          pID: selectedStaff.id,
          randevuTarih: selectedHour.toDate(),
          fiyat: totalPrice,
          sure: totalDuration,
          isWeb: device === 'web',
        })
      )
        return false

      if (selectedStaff.otomatikOnay) {
        await addRandevu(
          db.doc(salonId).collection('OnaylananRandevular'),
          true,
          totalPrice,
          totalDuration
        )
      } else {
        await addRandevu(
          db.doc(salonId).collection('BekleyenRandevular'),
          false,
          totalPrice,
          totalDuration
        )
      }

      const musteriSnapshot = await db
        .doc(salonId)
        .collection('Musteriler')
        .doc(userId)
        .get()

      await db
        .doc(salonId)
        .collection('Musteriler')
        .doc(userId)
        .update({
          lastBookDate: selectedHour.toDate(),
          lastAppointment: {
            pID: selectedStaff.id,
            hizmetler: Object.keys(selectedServices).map((el) => Number(el)),
          },
          penultimateAppointment: musteriSnapshot.data().lastAppointment
            ? musteriSnapshot.data().lastAppointment
            : {},
          penultimateBookDate: musteriSnapshot.data().lastBookDate
            ? musteriSnapshot.data().lastBookDate
            : {},
        })

      //props.setLoading(false)
      let sDevice = device ? '/' + device : ''
      let sDate = selectedHour.unix()
      if (selectedStaff.otomatikOnay) {
        window.location.href = '/success/approved/' + sDate + sDevice
      } else {
        window.location.href = '/success/pending/' + sDate + sDevice
      }
    })
  }

  return (
    <Accordion expanded={collapse === 'summary'}>
      <AccordionSummary onClick={() => goToCollapse('summary')}>
        <Typography>
          <b>Özet</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container>
            <Typography>
              <b>Seçilen Çalışan:</b>{' '}
              {selectedStaff.ad + ' ' + selectedStaff.soyad} <br />
              <b>Seçilen Hizmetler: </b>
              {Object.values(selectedServices)
                .map((service) => `${service.islemAdi}`)
                .join(', ')}
              <br />
              <b>Seçilen Saat:</b> {selectedHour.format('DD MMMM YYYY HH:mm')}{' '}
              <br />
              <b>Toplam Süre:</b>{' '}
              {_.sumBy(Object.values(selectedServices), (o) =>
                parseInt(o.islemSuresi)
              )}{' '}
              dk <br />
              <b>Toplam Ücret:</b>{' '}
              {_.sumBy(Object.values(selectedServices), (o) =>
                parseInt(o.islemUcreti)
              )}{' '}
              TL <br />
            </Typography>
          </Grid>
          <Grid
            container
            justify={'space-between'}
            style={{ marginTop: '24px' }}
          >
            <Button
              color={'primary'}
              variant={'outlined'}
              onClick={() => setCollapse('hours')}
            >
              Tarih/Saat seç
            </Button>
            <Button
              color={'primary'}
              variant={'contained'}
              onClick={() => approve()}
            >
              Onayla
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default OzetAccordion
