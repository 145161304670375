import React from 'react'
import {
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core'

const PersonelListItem = ({
  randevuStyle,
  staff,
  setStaffAndGetServices,
  setStaffAndGetHours,
  salonTur,
  availableStaffs,
}) => {
  const getStaffRole = (cinsiyet) => {
    return salonTur === 'Bay/Bayan Güzellik Salonu'
      ? ['Kadın Kuaförü', 'Erkek Kuaförü', 'Kadın/Erkek Kuaförü'][cinsiyet]
      : null
  }

  const getStaffFullName = () => {
    return staff.ad + '  ' + staff.soyad
  }

  const getStaffFirstChar = () => {
    return staff.ad.substring(0, 1).toUpperCase()
  }

  const getStaffAvatar = () => {
    return staff.resim ? (
      <Avatar src={staff.resimURL} />
    ) : (
      <Avatar
        style={{
          color: '#fff',
          backgroundColor: '#2196f3',
        }}
      >
        {getStaffFirstChar()}
      </Avatar>
    )
  }

  const setStaff = () => {
    if (randevuStyle === 'pht') {
      return setStaffAndGetServices(staff)
    } else {
      return setStaffAndGetHours(staff)
    }
  }

  const isStaffDisabled = () => {
    if (randevuStyle === 'hpt') return !availableStaffs.includes(staff.id)
    return false
  }

  return (
    <ListItem
      button
      key={staff.id}
      onClick={setStaff}
      disabled={isStaffDisabled(staff)}
    >
      <ListItemAvatar>{getStaffAvatar()}</ListItemAvatar>
      <ListItemText secondary={getStaffRole(staff.cinsiyet)}>
        {getStaffFullName()}
      </ListItemText>
    </ListItem>
  )
}

export default PersonelListItem
