import React from 'react'
import withContainer from '../../HOCs/withContainer'
import Typography from '@material-ui/core/Typography'
import Clock from '@material-ui/icons/Schedule'
import Grid from '@material-ui/core/Grid'
import { useParams } from 'react-router-dom'

const TimeZoneErrorPage = (props) => {
  const { type } = useParams()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '90vh' }}
    >
      <Grid item>
        <Typography variant="h6" color="inherit" align="center">
          <Clock
            style={{
              color: '#f44336',
              fontSize: 144,
            }}
            // style={{ fontSize:'40px' }}
            fontSize="large"
          ></Clock>
          <br></br>
          <div
            style={{
              color: '#444',
              fontSize: 20,
              fontWeight: 400,
            }}
          >
            {[
              'Telefonunuzun Saati Hatalı',
              <br key="1" />,
              'Lütfen telefon ayarlarınıza girerek saatinizi otomatik olarak ayarlayın.',
              <br key="2" />,
              'Eğer bu seçeneği bulamazsanız, zaman diliminizin!',
              <b> GMT+3 </b>,
              'olarak ayarlandığından emin olun.',
            ]}
          </div>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withContainer(TimeZoneErrorPage)
