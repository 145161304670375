import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Randevu from './Pages/Randevu/Randevu'
import TestRandevu from './Pages/Randevu/TestRandevu'
import OtomatikRandevu from './Pages/Randevu/OtomatikRandevu'
import Success from './Pages/Randevu/Success'
import Unauthorized from './Pages/Error/Unauthorized'
import NotFound from './Pages/Error/NotFound'
import ErrorPage from './Pages/Error/ErrorPage'
import TimeZoneErrorPage from './Pages/Error/TimeZoneErrorPage'
import moment from 'moment'

const Routes = (props) => {
  return (
    <Switch>
      {moment().parseZone().utcOffset() !== 180 && (
        <Route path={''} component={TimeZoneErrorPage} />
      )}
      <Route exact path={'/success'} component={Success} />
      <Route exact path={'/success/:type'} component={Success} />
      <Route exact path={'/success/:type/:sdate'} component={Success} />
      <Route exact path={'/success/:type/:sdate/:device'} component={Success} />
      <Route exact path={'/unauthorized'} component={Unauthorized} />
      <Route exact path={'/error/:type'} component={ErrorPage} />
      <Route
        exact
        path={'/:kuaforId/:userId/otomatik'}
        component={OtomatikRandevu}
      />
      <Route
        exact
        path={'/new/:salonId/:userId/:device'}
        component={TestRandevu}
      />
      <Route exact path={'/new/:salonId/:userId'} component={TestRandevu} />
      <Route exact path={'/:kuaforId/:userId/:device'} component={Randevu} />
      <Route exact path={'/:kuaforId/:userId'} component={Randevu} />
      <Route path={'*'} component={NotFound} />
    </Switch>
  )
}

export default Routes
