import React from 'react'
import {
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from '@material-ui/core'

const HizmetListItem = ({ service, addRemoveService, selectedServices }) => {
  const getLengthOfService = () => {
    return (
      'Süre: ' +
      (service.islemSuresi === 0
        ? 'Tanımlı Değil'
        : service.islemSuresi + ' dk') +
      '  Ücret: ' +
      (service.islemUcreti === 0 ? 'Tanımlı Değil' : service.islemUcreti)
    )
  }

  return (
    <ListItem
      button
      key={service.id}
      onClick={() => addRemoveService(service.id)}
    >
      <ListItemIcon>
        <Checkbox
          edge={'start'}
          disableRipple
          checked={!!selectedServices[service.id]}
        />
      </ListItemIcon>
      <ListItemText
        primary={service.islemAdi}
        secondary={getLengthOfService()}
      />
    </ListItem>
  )
}

export default HizmetListItem
