import React from 'react'
import Typography from '@material-ui/core/Typography'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  List,
} from '@material-ui/core'
import PersonelListItem from './PersonelListItem'

const PersonelAccordion = ({
  // Collapse
  collapse,
  goToCollapse,

  setStaffAndGetServices,
  setStaffAndGetHours,

  staffs,
  selectedSalon,
  selectedStaff,
  randevuStyle,

  availableStaffs,
}) => {
  const getTitle = () => {
    return selectedStaff && selectedStaff.ad && selectedStaff.soyad
      ? `Çalışan: ${selectedStaff.ad} ${selectedStaff.soyad}`
      : 'Çalışan Seçiniz'
  }

  return (
    <Accordion expanded={collapse === 'staff'}>
      <AccordionSummary onClick={() => goToCollapse('staff')}>
        <Typography>
          <b>{getTitle()}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container>
            <List style={{ width: '100%' }}>
              {staffs.map((staff) => (
                <PersonelListItem
                  key={staff.id}
                  staff={staff}
                  setStaffAndGetServices={setStaffAndGetServices}
                  setStaffAndGetHours={setStaffAndGetHours}
                  salonTur={selectedSalon.salonTur}
                  randevuStyle={randevuStyle}
                  availableStaffs={availableStaffs}
                />
              ))}
            </List>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default PersonelAccordion
