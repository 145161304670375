import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import withContainer from '../../HOCs/withContainer'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import Collapse from '@material-ui/core/Collapse'
import WcIcon from '@material-ui/icons/Wc'
import moment from 'moment'
import { DatePicker } from '@material-ui/pickers'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import * as _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'

import ButtonGroup from '@material-ui/core/ButtonGroup'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

const Randevu = (props) => {
  //const location = useLocation()
  const history = useHistory()
  const { kuaforId, userId, device } = useParams()
  const db = window.firebase.firestore().collection('kuaforumDB')
  //const dbTakvim = window.firebase.firestore().collection('personelDB')
  const dbSalon = window.firebase.firestore().collection('Salonlar')

  const [collapse, setRealCollapse] = React.useState()
  const [lastCollapse, setLastCollapse] = React.useState()
  const [berberHours, setBerberHours] = React.useState({})
  const [staffs, setStaffs] = React.useState([])
  const [customerData, setCustomer] = React.useState({})
  const [services, setServices] = React.useState([])
  const [appointments, setAppointments] = React.useState([])
  const [selectedStaff, setSelectedStaff] = React.useState({})
  //const [selectedStaffTakvim, setSelectedStaffTakvim] = React.useState({})
  const [selectedDate, setSelectedDate] = React.useState(moment())
  const [tempSelectedDate, setTempSelectedDate] = React.useState(0)
  const [selectedHour, setSelectedHour] = React.useState(moment())
  const [availableHours, setAvailableHours] = React.useState([])
  const [selectedServices, setSelectedServices] = React.useState({})
  const [disabledServices, setDisabledServices] = React.useState([])
  const [availableStaffs, setAvailableStaffs] = React.useState([])
  //const [disableAuto, setDisableAuto] = React.useState(false)
  const [warning, setWarning] = React.useState('')
  const [selectedSalon, setSelectedSalon] = React.useState({})
  const [goingBack, setGoingBack] = React.useState(false)
  const [isApproved, setIsApproved] = React.useState(false)

  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper
    },
    nested: {
      paddingLeft: theme.spacing(4)
    }
  }))

  const classes = useStyles()
  const [openKadin, setOpenKadin] = React.useState(true)
  const [openErkek, setOpenErkek] = React.useState(true)

  const handleKadinClick = () => {
    setOpenKadin(!openKadin)
  }
  const handleErkekClick = () => {
    setOpenErkek(!openErkek)
  }

  // App init
  React.useEffect(() => {
    init()
  }, [])

  function getYemekAsRandevu() {
    let yemek = []
    //console.log('kac')
    // yemek saatleri kontrolü
    if (selectedStaff.yemek && selectedStaff.yemekBitis) {
      let date = {
        start: selectedDate
          .clone()
          .startOf('day')
          .add(
            moment(selectedStaff.yemek, 'HH:mm').diff(
              moment().startOf('day'),
              'minutes'
            ),
            'minutes'
          ),
        end: selectedDate
          .clone()
          .startOf('day')
          .add(
            moment(selectedStaff.yemekBitis, 'HH:mm').diff(
              moment().startOf('day'),
              'minutes'
            ),
            'minutes'
          )
      }
      //console.log('date', date)
      yemek.push({
        id: 'takvim' + date.start.unix() * 1000,
        start: date.start.startOf('minute'),
        end: date.end.startOf('minute')
        /*
        randevuTarih: {
          toMillis: () => {
            return Number(date.start)
          },
        },
        sure: moment(date.end).diff(moment(date.start)) / 60 / 1000,
        */
      })
    }
    return yemek
  }

  function getRandevularAsStartEnd(app) {
    let randevular = []
    app.forEach((randevu) => {
      if (randevu.sure === 0) {
        // //  console.log('dadi dadi sure 0')
        return
      }
      //console.log(randevu)
      randevular.push({
        id: randevu.id,
        start: moment(randevu.randevuTarih.seconds * 1000).startOf('minute'),
        end: moment(randevu.randevuTarih.seconds * 1000)
          .add(randevu.sure, 'minutes')
          .startOf('minute')
      })
    })

    // //  console.log('Start-End Randevular', randevular)
    return randevular
  }

  function getAvailableHours(startStr, endStr, randevular) {
    let hours = []
    let durationAsUnix = 0

    let start = parseInt(startStr)
    let end = parseInt(endStr)
    let startMin = parseInt(startStr.split(':')[1])
    let endMin = parseInt(endStr.split(':')[1])

    // İşlem süresinin ne kadar uzun süreceğini hesapla
    Object.values(selectedServices).forEach((el) => {
      durationAsUnix += Number(el.islemSuresi) * 60
    })

    let durationAsMinutes = durationAsUnix / 60

    //console.log('durationAsUnix', durationAsUnix)
    //console.log(start, end, randevular)

    function getDateFromI(i) {
      // başlangıç ve bitiş tarihi arasında
      if (i < start * 60 || i >= (endMin ? end + 1 : end) * 60) {
        return { goOn: true }
      }

      let date = moment(selectedDate)
        .startOf('day')
        .add(i, 'minutes')
        .startOf('minutes')
      let status = true

      // eğer küsüratlı bir açılış saatine sahipse, saatin  o kısımlarını kırmızı yap
      if (i < start * 60 + startMin || i >= end * 60 + endMin) {
        return { status: false, date }
      }

      // şu andan önceki saatleri kırmızıya boya
      if (date.unix() < moment().unix()) {
        status = false
      }

      // son saatleri kırmızıya boya
      if (
        date.unix() >
        date
          .clone()
          .startOf('day')
          .add(end * 60 + endMin - durationAsMinutes, 'minutes')
          .unix()
      ) {
        //flex
        if (
          !selectedStaff.flex ||
          durationAsMinutes < 15 ||
          i + durationAsMinutes - 15 > end * 60 + endMin
        ) {
          status = false
        } else {
          // //  console.log('flex:', date)
        }
      }

      // randevularla çakışıyorsa kırmızıya boya
      randevular.forEach((randevu) => {
        if (
          date.unix() > randevu.start.unix() - durationAsUnix &&
          date.unix() < randevu.end.unix()
        ) {
          status = false
        }
      })

      return { date, status, goOn: false }
    }

    let kullaniciIstegi = '10'
    if (selectedStaff && selectedStaff.aralik && selectedStaff.aralik !== '0') {
      kullaniciIstegi = selectedStaff.aralik || '10'
    } else {
      kullaniciIstegi = selectedSalon ? selectedSalon.aralik || '10' : '10'
    }
    let toN = Number(kullaniciIstegi)
    if (isNaN(toN) || toN < 5 || toN > 60) toN = 5

    // Her dakika için uygunluğu kontrol et
    for (let i = 0; i < 24 * 60; i++) {
      // her 15 dakika için pushla
      let dividable = i % toN === 0

      if (dividable) {
        // console.log(
        //   'Dividable. If status false, check next N to find available slot'
        // )
        let { date, status, goOn } = getDateFromI(i)
        if (goOn) {
          continue
        }

        if (!status) {
          // console.log('check next N to find available slot')
          for (let z = 0; z < toN; z++) {
            let {
              date: nextAvailableDate,
              status: nextAvailableStatus,
              goOn: nextAvailableGoOn
            } = getDateFromI(i + z)

            if (nextAvailableGoOn) {
              continue
            }

            if (nextAvailableStatus) {
              date = nextAvailableDate
              status = nextAvailableStatus
              break
            }
          }
        }

        let h = date.format('H')
        if (!hours[h]) {
          hours[h] = []
        }

        /*
        if (!status) {
          for (let j = 1; j < 10; j++) {
            let newDate = getDateFromI(i - j)
            let newStatus = newDate.status
            if (newStatus) {
              date = newDate.date
              status = newDate.status
              // //  console.log('new date', j)
              break
            }
          }
        }
        */

        hours[h].push({
          status,
          date
        })
      }
    }

    // tamamı kırmızı olan satırları temizle
    hours = hours.filter((hourRow) => !hourRow.every((hour) => !hour.status))

    return hours
  }

  React.useEffect(() => {
    let tArr = [
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Perşembe',
      'Cuma',
      'Cumartesi',
      'Pazar'
    ]

    if (selectedStaff.id) {
      const holiday = parseInt(selectedStaff.izin)
      const holidaySalon =
        parseInt(berberHours.bosGun) + 1 ? parseInt(berberHours.bosGun) : -1
      let todayCode = (parseInt(selectedDate.clone().day()) + 6) % 7
      // //  console.log('Yukarıda:', todayCode)
      // //  console.log('Yukarıda Personel: ', holiday)
      // //  console.log('Yukarıda Salon:', holidaySalon)

      if (todayCode === holiday) {
        setWarning(
          `${tArr[todayCode]} günü, personelin izin gününe geldiğinden tarih otomatik olarak değiştirildi.`
        )
        setTempSelectedDate(selectedDate.clone().add(goingBack ? -1 : 1, 'day'))
        setGoingBack(false)
      } else if (todayCode === holidaySalon) {
        setWarning(
          `${tArr[todayCode]} günü, salonun izin gününe geldiğinden tarih otomatik olarak değiştirildi.`
        )
        setTempSelectedDate(selectedDate.clone().add(goingBack ? -1 : 1, 'day'))
        setGoingBack(false)
      } else {
        let hours = getAvailableHours(
          selectedStaff.calisma || berberHours.basSaat,
          selectedStaff.calismaBitis || berberHours.bitSaat,
          [...getRandevularAsStartEnd(appointments), ...getYemekAsRandevu()]
        )

        // //  console.log('avhours', hours)

        // Eğer bugüne alınabilecek randevu yoksa, bir sonraki güne bak
        if (hours.length === 0) {
          setWarning(
            `${tArr[todayCode]} günü müsait randevu bulunmadığından tarih otomatik olarak değiştirildi.`
          )
          setTempSelectedDate(
            selectedDate.clone().add(goingBack ? -1 : 1, 'day')
          )
          setGoingBack(false)
        } else {
          setAvailableHours(hours)
        }
      }
    }
    return () => {}
  }, [selectedDate])

  React.useEffect(() => {
    if (tempSelectedDate !== 0) {
      //console.log('appointment değişti???')
      setSelectedDate(tempSelectedDate.clone())
    }
  }, [appointments])

  React.useEffect(() => {
    if (tempSelectedDate !== 0) {
      getAppointments()
    }
  }, [tempSelectedDate])

  React.useEffect(() => {
    if (selectedSalon && staffs.length > 0) {
      if (selectedSalon.randevuStili === 'pht' || !selectedSalon.randevuStili) {
        setCollapse('staff')
      }

      if (selectedSalon.randevuStili === 'hpt') {
        getServicesFromAllStaff()
      }
    }
  }, [selectedSalon, staffs])

  const init = async () => {
    props.setLoading(true)
    await getBerber()
    await getSalon()
    await getStaffs()
    await getCustomer()
    props.setLoading(false)
  }

  const getDocumentsFromCollection = async (collection) => {
    const events = await window.firebase.firestore().collection(collection)
    return await events.get().then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
  }

  const getBerber = async () => {
    const berber = await db
      .doc(kuaforId)
      .collection('Bilgiler')
      .doc('zaman')
      .get()
    if (!berber.exists) {
      history.push('/error/no-staff')
    }
    setBerberHours(berber.data())
  }

  const getSalon = async () => {
    const salon = await dbSalon.doc(kuaforId).get()
    if (!salon.exists) {
      history.push('/error/no-saloon')
    }

    if (
      !salon.data() ||
      !(salon.data().salonAktif && !salon.data().salonPasif)
    ) {
      history.push('/error/passive-saloon')
    } else {
      // //  console.log(
      //   'PA',
      //   salon.data().salonPasif,
      //   salon.data().salonAktif,
      //   salon.data()
      // )
      setSelectedSalon(salon.data())
    }
  }

  const getCustomer = async () => {
    const customer = await db
      .doc(kuaforId)
      .collection('Musteriler')
      .doc(userId)
      .get()
    if (!customer.exists) {
      history.push('/error/no-customer')
    }

    if (customer.data().isBanned) {
      history.push('/error/banned-customer')
    }

    let tempData = customer.data()

    setCustomer(tempData)
    return tempData
  }

  const getStaffs = async () => {
    const snapShot = await db.doc(kuaforId).collection('Personeller').get()
    let staffList = []
    snapShot.forEach((staff) => {
      if (!staff.data().deleted) {
        staffList.push({
          id: staff.id,
          ...staff.data()
          //resimURL: await getStaffImage(staff.data().resim),
        })
      }
    })
    // //  console.log('staffList', staffList)

    staffList = staffList.map((el) => ({
      ...el,
      index: el.index < 0 || el.index === undefined ? 999 : el.index
    }))

    staffList
      .sort(function (a, b) {
        if (a.firstname < b.firstname) {
          return -1
        }
        if (a.firstname > b.firstname) {
          return 1
        }
        return 0
      })
      .sort((a, b) => a.index - b.index)

    if (staffList && staffList.length === 0) {
      history.push('/error/no-staff')
    }

    let s = await Promise.all(
      staffList.map((staff) => {
        return new Promise((resolve, reject) => {
          window.firebase
            .storage()
            .ref(`/${kuaforId}/personeller/${staff.resim}`)
            .getDownloadURL()
            .then((url) => {
              resolve(url)
            })
            .catch((err) => {
              resolve('')
            })
        })
      })
    )

    staffList = staffList.map((el, i) => ({ ...el, resimURL: s[i] }))

    setStaffs(staffList)

    if (
      staffList.length === 1 &&
      (selectedSalon.randevuStili === 'pht' || !selectedSalon.randevuStili)
    ) {
      setStaffAndGetServices(staffList[0])
    }

    return staffList
  }

  const getIslemSuresi = (staff, service) => {
    if (typeof staff.hizmetler[service.id] == 'object') {
      if (staff.hizmetler[service.id][1] === -1) {
        return service.data().islemSuresi < 10 ? 10 : service.data().islemSuresi
      }
      return staff.hizmetler[service.id][1]
    }
    return service.data().islemSuresi
  }

  const getIslemUcreti = (staff, service) => {
    if (typeof staff.hizmetler[service.id] == 'object') {
      return staff.hizmetler[service.id][0] === -1
        ? service.data().islemUcreti
        : staff.hizmetler[service.id][0]
    }
    return staff.hizmetler[service.id] === -1
      ? service.data().islemUcreti
      : staff.hizmetler[service.id]
  }

  const getServicesFromAllStaff = async () => {
    props.setLoading(true)
    const snapShot = await db.doc(kuaforId).collection('Hizmetler').get()
    const serviceList = []
    snapShot.forEach((service) => {
      staffs.forEach((staff) => {
        if (Object.keys(staff.hizmetler).includes(service.id)) {
          if (serviceList.find((el) => el.id == service.id)) {
            // do nothing
          } else {
            serviceList.push({
              id: service.id,
              ...service.data(),
              ...{
                islemSuresi: getIslemSuresi(staff, service),
                islemUcreti: getIslemUcreti(staff, service)
              }
            })
          }
        }
      })
    })

    let favoriteServices = serviceList.filter((el) => el.favorite)

    let notSoFavoriteServices = serviceList.filter((el) => !el.favorite)

    favoriteServices.sort(function (a, b) {
      return a.id - b.id
    })

    notSoFavoriteServices.sort(function (a, b) {
      return a.id - b.id
    })

    let rearrangedServiceList = [...favoriteServices, ...notSoFavoriteServices]

    // //  console.log('serviceList', serviceList, rearrangedServiceList)

    setServices(rearrangedServiceList)
    setCollapse('service')
    setOpenKadin(true)
    setOpenErkek(true)

    //console.log(serviceList, staff)
    props.setLoading(false)
  }

  const setServicesAndGetStaff = async () => {
    props.setLoading(true)
    setAvailableStaffs(
      staffs
        .filter((staff) => {
          return Object.values(selectedServices).every((service) => {
            return Object.keys(staff.hizmetler).includes(String(service.id))
          })
        })
        .map((el) => el.id)
    )
    setCollapse('staff')
    props.setLoading(false)
  }

  const setStaffAndGetHours = async (staff) => {
    props.setLoading(true)
    setSelectedStaff(staff)
    setCollapse('hours')
    setTempSelectedDate(moment())
    //console.log(serviceList, staff)
    props.setLoading(false)
  }

  const setStaffAndGetServices = async (staff) => {
    props.setLoading(true)
    setSelectedStaff(staff)
    const snapShot = await db.doc(kuaforId).collection('Hizmetler').get()
    const serviceList = []
    snapShot.forEach((service) => {
      if (Object.keys(staff.hizmetler).includes(service.id)) {
        serviceList.push({
          id: service.id,
          ...service.data(),
          ...{
            islemSuresi: Number(
              typeof staff.hizmetler[service.id] == 'object'
                ? staff.hizmetler[service.id][1] === -1
                  ? service.data().islemSuresi < 0
                    ? 0
                    : service.data().islemSuresi
                  : staff.hizmetler[service.id][1]
                : service.data().islemSuresi
            ),

            islemUcreti: Number(
              typeof staff.hizmetler[service.id] == 'object'
                ? staff.hizmetler[service.id][0] === -1
                  ? service.data().islemUcreti
                  : staff.hizmetler[service.id][0]
                : staff.hizmetler[service.id] === -1
                ? service.data().islemUcreti
                : staff.hizmetler[service.id]
            )
          }
        })
      }
    })

    let favoriteServices = serviceList.filter((el) => el.favorite)
    let notSoFavoriteServices = serviceList.filter((el) => !el.favorite)

    favoriteServices.sort(function (a, b) {
      return a.id - b.id
    })

    notSoFavoriteServices.sort(function (a, b) {
      return a.id - b.id
    })

    let rearrangedServiceList = [...favoriteServices, ...notSoFavoriteServices]

    // //  console.log('serviceList', serviceList, rearrangedServiceList)

    setServices(rearrangedServiceList)
    setCollapse('service')
    setOpenKadin(true)
    setOpenErkek(true)

    if (selectedStaff.id !== staff.id) {
      setSelectedServices({})
    }
    //console.log(serviceList, staff)
    props.setLoading(false)
  }

  const getOnlyAppointments = async (callback) => {
    let refConfirm = db.doc(kuaforId).collection('OnaylananRandevular')
    let refPending = db.doc(kuaforId).collection('BekleyenRandevular')

    let snapShotConfirmed = await refConfirm
      .doc(`${tempSelectedDate.clone().startOf('month').unix()}`)
      .collection('get')
      .get()

    let snapShotPending = await refPending
      .doc(`${tempSelectedDate.clone().startOf('month').unix()}`)
      .collection('get')
      .get()

    const snapShotRandevular = [
      ...snapShotConfirmed.docs,
      ...snapShotPending.docs
    ]

    const randevuList = []

    let personelTakvimi = await getDocumentsFromCollection(
      `personelDB/${selectedStaff.id}/Takvim`
    )

    // //  console.log('personelTakvimi', personelTakvimi)

    personelTakvimi.forEach((eventObj) => {
      eventObj.events.forEach((date) => {
        //console.log(date)
        randevuList.push({
          id: 'takvim' + date.start,
          randevuTarih: {
            toMillis: () => {
              // GMT + 3
              return Number(date.start * 1000)
            },
            seconds: Math.floor(date.start / 1000) - 3 * 60 * 60
          },
          sure: moment(date.end).diff(moment(date.start)) / 60 / 1000
        })
      })
    })

    snapShotRandevular.forEach((randevu) => {
      //console.log(randevu)

      //console.log('bbb', selectedStaff, randevu.data(), randevu.id)

      if (selectedStaff.id === randevu.data().pID) {
        //console.log({ id: randevu.id, ...randevu.data() })
        randevuList.push({ id: randevu.id, ...randevu.data() })
      }
    })

    // //  console.log('randevuList', randevuList)
    callback(randevuList)
  }

  const getAppointments = async () => {
    props.setLoading(true)
    getOnlyAppointments((randevuList) => {
      setAppointments(randevuList)
      setCollapse('hours')
      props.setLoading(false)
    })
  }

  const setSelectedHourAndShowSummary = (hour) => {
    setSelectedHour(hour)
    setCollapse('summary')
  }

  const addTimeToSelectedDate = (val) => {
    if (val < 0) {
      setGoingBack(true)
    }
    setWarning('')
    setTempSelectedDate(selectedDate.clone().add(1 * val, 'day'))
  }

  const setCollapse = (val) => {
    // //  console.log('setting collapse', val)
    setLastCollapse(val)
    setRealCollapse(val)
    // //  console.log('%c %s %s', 'color:red', val, collapse)
  }

  const goToCollapse = (val) => {
    // collapse can be

    let collapseOrder =
      selectedSalon.randevuStili === 'pht' || !selectedSalon.randevuStili
        ? ['staff', 'service', 'hours', 'summary']
        : ['service', 'staff', 'hours', 'summary']
    // //  console.log(val)
    if (
      collapseOrder.findIndex((e) => e === lastCollapse) >=
      collapseOrder.findIndex((e) => e === val)
    ) {
      // //  console.log('last:', lastCollapse, 'now:', val)
      // only show, if something changes lastcollapse will change
      setRealCollapse(val)
    }
  }

  const lastCheck = (callback) => {
    getOnlyAppointments((randevuList) => {
      let hours = getAvailableHours(
        selectedStaff.calisma || berberHours.basSaat,
        selectedStaff.calismaBitis || berberHours.bitSaat,
        [...getRandevularAsStartEnd(randevuList), ...getYemekAsRandevu()]
      )

      callback(
        hours
          .map((e) => e.filter((el) => el.status).map((v) => v.date))
          .flat()
          .some((e) => {
            // //  console.log(
            //   e.unix() === selectedHour.unix(),
            //   e.unix(),
            //   selectedHour.unix()
            // )
            return e.unix() === selectedHour.unix()
          })
      )
    })
  }

  const approve = async () => {
    if (isApproved) return
    setIsApproved(true)
    props.setLoading(true)
    lastCheck(async (isValidDate) => {
      if (!isValidDate) {
        alert(
          'Randevu tarihi artık geçerli değil. Lütfen geçerli bir randevu tarihi seçin.'
        )
        getAppointments()
        setIsApproved(false)
        props.setLoading(false)
        return
      }

      //const currentUser = window.firebase.auth().currentUser;
      const currentUser = {
        uid: userId
      }
      const refConfirm = db.doc(kuaforId).collection('OnaylananRandevular')
      const refPending = db.doc(kuaforId).collection('BekleyenRandevular')
      //const refReject = db.doc(kuaforId).collection('ReddedilenRandevular')
      /*
      const refReportDay = db
        .doc(kuaforId)
        .collection('Raporlar')
        .doc('gunlukRandevular')
      const refReportWeek = db
        .doc(kuaforId)
        .collection('Raporlar')
        .doc('haftalikRandevular')
      const refReportMonth = db
        .doc(kuaforId)
        .collection('Raporlar')
        .doc('aylikRandevular')
      const incrementBooking = window.firebase.firestore.FieldValue.increment(1)
      */
      const totalDuration = _.sumBy(Object.values(selectedServices), (o) =>
        parseInt(o.islemSuresi)
      )
      const totalPrice = _.sumBy(Object.values(selectedServices), (o) =>
        parseInt(o.islemUcreti)
      )

      // test if all is ok
      let testObject = {
        mID: currentUser.uid,
        mAd: `${customerData.firstname} ${customerData.lastname}`,
        mTel: customerData.phone,
        pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
        sAd: selectedSalon.salonAd,
        pID: selectedStaff.id,
        randevuTarih: selectedHour.toDate(),
        fiyat: totalPrice,
        sure: totalDuration,
        isWeb: device === 'web'
      }

      if (
        !Object.values(testObject).every(
          (val) => val !== undefined && val !== null
        )
      ) {
        return false
      }

      if (selectedStaff.otomatikOnay) {
        //pasif:onaylananrandevular   diğeri: bekleyenrandevular   reddedilenrandevular
        // ootomatik onay varsa randevular(pasifRandevular)/ay başlangıcı/get/ranID
        //raporlar altında aynı yapı => count: randevu sayısı 1 arttır , income: randevu ücret ekle. Bu yalnızca otomatik onayda olacak.

        //console.log('hello', selectedDate.clone().startOf('month').unix())

        await refConfirm
          .doc(`${selectedDate.clone().startOf('month').unix()}`)
          .collection('get')
          .doc()
          .set({
            hizmetler: Object.keys(selectedServices),
            // hizmetler: Object.values(selectedServices).map(
            // (service) => `${service.islemAdi} `
            // ),
            favori: false,
            mID: currentUser.uid,
            mAd: `${customerData.firstname} ${customerData.lastname}`,
            mTel: customerData.phone,
            pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
            sAd: selectedSalon.salonAd,
            pID: selectedStaff.id,
            randevuTarih: selectedHour.toDate(),
            fiyat: totalPrice,
            sure: totalDuration,
            randevuRol: 'Musteri',
            randevuTur: 'Normal',
            onay: true,
            isWeb: device === 'web'
          })
        /*
        const incrementBookPrice = window.firebase.firestore.FieldValue.increment(
          totalPrice
        )
       
        const randevuGunlukRapor = await refReportDay
          .collection(`${selectedDate.clone().startOf('day').unix()}`)
          .doc('get')
          .set(
            {
              count: incrementBooking,
              income: incrementBookPrice,
            },
            { merge: true }
          )
  
        const randevuHaftalikRapor = await refReportWeek
          .collection(`${selectedDate.clone().startOf('week').unix()}`)
          .doc('get')
          .set(
            {
              count: incrementBooking,
              income: incrementBookPrice,
            },
            { merge: true }
          )
  
        // //  console.log(
          'aylik kaydediliyor',
          selectedDate.clone().startOf('month').unix()
        )
  
        const randevuAylikRapor = await refReportMonth
          .collection(`${selectedDate.clone().startOf('month').unix()}`)
          .doc('get')
          .set(
            {
              count: incrementBooking,
              income: incrementBookPrice,
            },
            { merge: true }
          )
          */
      } else {
        await refPending
          .doc(`${selectedDate.clone().startOf('month').unix()}`)
          .collection('get')
          .add({
            hizmetler: Object.keys(selectedServices),
            // hizmetler: Object.values(selectedServices).map(
            // (service) => `${service.islemAdi} `
            // ),
            favori: false,
            mID: currentUser.uid,
            mAd: `${customerData.firstname} ${customerData.lastname}`,
            mTel: customerData.phone,
            pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
            sAd: selectedSalon.salonAd,
            pID: selectedStaff.id,
            randevuTarih: selectedHour.toDate(),
            fiyat: totalPrice,
            sure: totalDuration,
            randevuRol: 'Musteri',
            randevuTur: 'Normal',
            onay: false,
            isWeb: device === 'web'
          })
      }

      const musteriSnapshot = await db
        .doc(kuaforId)
        .collection('Musteriler')
        .doc(currentUser.uid)
        .get()

      await db
        .doc(kuaforId)
        .collection('Musteriler')
        .doc(currentUser.uid)
        .update({
          lastBookDate: selectedHour.toDate(),
          lastAppointment: {
            pID: selectedStaff.id,
            hizmetler: Object.keys(selectedServices).map((el) => Number(el))
          },
          penultimateAppointment: musteriSnapshot.data().lastAppointment
            ? musteriSnapshot.data().lastAppointment
            : {},
          penultimateBookDate: musteriSnapshot.data().lastBookDate
            ? musteriSnapshot.data().lastBookDate
            : {}
        })
      /*
      
      const personel = await db
        .doc(kuaforId)
        .collection('Personeller')
        .doc(selectedStaff.id)
        .update({
          randevular: window.firebase.firestore.FieldValue.arrayUnion(
            refConfirm.id
          ),
        })
    */

      props.setLoading(false)
      let sdevice = device ? '/' + device : ''
      let sdate = selectedHour.unix()
      if (selectedStaff.otomatikOnay) {
        window.location.href = '/success/approved/' + sdate + sdevice
      } else {
        window.location.href = '/success/pending/' + sdate + sdevice
      }
    })
  }

  const addRemoveService = (sid) => {
    if (disabledServices.includes(sid)) {
      return
    }

    let _selectedServices = Object.assign({}, selectedServices)
    if (_selectedServices[sid]) {
      delete _selectedServices[sid]
    } else {
      const _service = _.findLast(services, { id: sid })
      _selectedServices[sid] = _service
    }

    setLastCollapse('service')

    // //  console.log('_selectedServices', _selectedServices)
    // //  console.log('disabledServices', disabledServices)

    let k = staffs
      .filter((staff) => {
        return Object.values(_selectedServices).every((service) => {
          return Object.keys(staff.hizmetler).includes(String(service.id))
        })
      })
      .map((el) => el.hizmetler)

    let availableServices = Object.keys(Object.assign({}, ...k)).map(Number)

    setDisabledServices(
      services.map((el) => el.id).filter((k) => !availableServices.includes(k))
    )

    //console.log(_selectedServices)
    setSelectedServices(_selectedServices)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {selectedSalon.randevuStili === 'pht' || !selectedSalon.randevuStili ? (
          <div>
            {/*Çalışan Seç*/}
            <Accordion expanded={collapse === 'staff'}>
              <AccordionSummary onClick={() => goToCollapse('staff')}>
                <Typography>
                  <b>
                    {selectedStaff && selectedStaff.ad && selectedStaff.soyad
                      ? `Çalışan: ${selectedStaff.ad} ${selectedStaff.soyad}`
                      : 'Çalışan Seçiniz'}
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid container>
                    <List style={{ width: '100%' }}>
                      {staffs.map((staff) => (
                        <ListItem
                          button
                          key={staff.id}
                          onClick={() => {
                            setStaffAndGetServices(staff)
                          }}
                        >
                          <ListItemAvatar>
                            {staff.resim ? (
                              <Avatar src={staff.resimURL} />
                            ) : (
                              <Avatar
                                style={{
                                  color: '#fff',
                                  backgroundColor: '#2196f3'
                                }}
                              >
                                {staff.ad.substring(0, 1)}
                              </Avatar>
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            secondary={
                              selectedSalon.salonTur ===
                              'Bay/Bayan Güzellik Salonu'
                                ? [
                                    'Kadın Kuaförü',
                                    'Erkek Kuaförü',
                                    'Kadın/Erkek Kuaförü'
                                  ][staff.cinsiyet]
                                : null
                            }
                          >
                            {staff.ad + '  ' + staff.soyad}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/*Hizmet Seç*/}
            <Accordion expanded={collapse === 'service'}>
              <AccordionSummary onClick={() => goToCollapse('service')}>
                <Typography>
                  <b>
                    {Object.values(selectedServices).length > 0
                      ? `Hizmet: ${Object.values(selectedServices)
                          .map((el) => {
                            //console.log(el)
                            return el.islemAdi
                          })
                          .join(', ')}`
                      : 'Hizmet Seçiniz'}
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid container>
                    <List style={{ width: '100%' }}>
                      {services.some((el) => el.cinsiyet === 0) ? (
                        <ListItem button onClick={handleKadinClick}>
                          <ListItemIcon>
                            <WcIcon />
                          </ListItemIcon>
                          <ListItemText primary="Kadın" />
                          {openKadin ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                      ) : (
                        ''
                      )}

                      <Collapse in={openKadin} timeout="auto" unmountOnExit>
                        <List
                          button="true"
                          className={classes.nested}
                          component="div"
                          disablePadding
                        >
                          {services
                            .filter((el) => el.cinsiyet === 0)
                            .map((service) => (
                              <ListItem
                                button
                                key={service.id}
                                onClick={() => addRemoveService(service.id)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge={'start'}
                                    disableRipple
                                    checked={!!selectedServices[service.id]}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={service.islemAdi}
                                  secondary={
                                    'Süre: ' +
                                    (service.islemSuresi === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemSuresi + ' dk') +
                                    '  Ücret: ' +
                                    (service.islemUcreti === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemUcreti)
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Collapse>
                      {services.some((el) => el.cinsiyet === 1) ? (
                        <ListItem button onClick={handleErkekClick}>
                          <ListItemIcon>
                            <WcIcon />
                          </ListItemIcon>
                          <ListItemText primary="Erkek" />
                          {openErkek ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                      ) : (
                        ''
                      )}
                      <Collapse in={openErkek} timeout="auto" unmountOnExit>
                        <List
                          button="true"
                          className={classes.nested}
                          component="div"
                          disablePadding
                        >
                          {services
                            .filter((el) => el.cinsiyet === 1)
                            .map((service) => (
                              <ListItem
                                button
                                key={service.id}
                                onClick={() => addRemoveService(service.id)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge={'start'}
                                    disableRipple
                                    checked={!!selectedServices[service.id]}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={service.islemAdi}
                                  secondary={
                                    'Süre: ' +
                                    (service.islemSuresi === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemSuresi + ' dk') +
                                    '  Ücret: ' +
                                    (service.islemUcreti === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemUcreti)
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Collapse>
                    </List>
                  </Grid>
                  <Grid container justify={'space-between'}>
                    <Button
                      color={'primary'}
                      variant={'outlined'}
                      onClick={() => setCollapse('staff')}
                    >
                      Çalışan Seç
                    </Button>
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      onClick={() => {
                        //console.log('going brave')
                        setTempSelectedDate(moment())
                      }}
                      disabled={
                        Object.keys(selectedServices).filter(
                          (s) => selectedServices[s]
                        ).length === 0
                      }
                    >
                      Tarih/Saat Seç
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        ) : (
          <div>
            {/*Hizmet Seç*/}
            <Accordion expanded={collapse === 'service'}>
              <AccordionSummary onClick={() => goToCollapse('service')}>
                <Typography>
                  <b>
                    {Object.values(selectedServices).length > 0
                      ? `Hizmet: ${Object.values(selectedServices)
                          .map((el) => {
                            //console.log(el)
                            return el.islemAdi
                          })
                          .join(', ')}`
                      : 'Hizmet Seçiniz'}
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid container>
                    <List style={{ width: '100%' }}>
                      {services.some((el) => el.cinsiyet === 0) ? (
                        <ListItem button onClick={handleKadinClick}>
                          <ListItemIcon>
                            <WcIcon />
                          </ListItemIcon>
                          <ListItemText primary="Kadın" />
                          {openKadin ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                      ) : (
                        ''
                      )}

                      <Collapse in={openKadin} timeout="auto" unmountOnExit>
                        <List
                          button="true"
                          className={classes.nested}
                          component="div"
                          disablePadding
                        >
                          {services
                            .filter((el) => el.cinsiyet === 0)
                            .map((service) => (
                              <ListItem
                                button
                                key={service.id}
                                onClick={() => addRemoveService(service.id)}
                                disabled={disabledServices.includes(service.id)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge={'start'}
                                    disableRipple
                                    checked={!!selectedServices[service.id]}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={service.islemAdi}
                                  secondary={
                                    'Süre: ' +
                                    (service.islemSuresi === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemSuresi + ' dk') +
                                    '  Ücret: ' +
                                    (service.islemUcreti === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemUcreti)
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Collapse>
                      {services.some((el) => el.cinsiyet === 1) ? (
                        <ListItem button onClick={handleErkekClick}>
                          <ListItemIcon>
                            <WcIcon />
                          </ListItemIcon>
                          <ListItemText primary="Erkek" />
                          {openErkek ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                      ) : (
                        ''
                      )}
                      <Collapse in={openErkek} timeout="auto" unmountOnExit>
                        <List
                          button="true"
                          className={classes.nested}
                          component="div"
                          disablePadding
                        >
                          {services
                            .filter((el) => el.cinsiyet === 1)
                            .map((service) => (
                              <ListItem
                                button
                                key={service.id}
                                onClick={() => addRemoveService(service.id)}
                                disabled={disabledServices.includes(service.id)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    edge={'start'}
                                    disableRipple
                                    checked={!!selectedServices[service.id]}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={service.islemAdi}
                                  secondary={
                                    'Süre: ' +
                                    (service.islemSuresi === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemSuresi + ' dk') +
                                    '  Ücret: ' +
                                    (service.islemUcreti === 0
                                      ? 'Tanımlı Değil'
                                      : service.islemUcreti)
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Collapse>
                    </List>
                  </Grid>
                  <Grid container justify={'flex-end'}>
                    <Button
                      color={'primary'}
                      variant={'contained'}
                      onClick={() => {
                        //console.log('going brave')
                        setServicesAndGetStaff()
                      }}
                      disabled={
                        Object.keys(selectedServices).filter(
                          (s) => selectedServices[s]
                        ).length === 0
                      }
                    >
                      Çalışan Seç
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            {/*Çalışan Seç*/}
            <Accordion expanded={collapse === 'staff'}>
              <AccordionSummary onClick={() => goToCollapse('staff')}>
                <Typography>
                  <b>
                    {selectedStaff && selectedStaff.ad && selectedStaff.soyad
                      ? `Çalışan: ${selectedStaff.ad} ${selectedStaff.soyad}`
                      : 'Çalışan Seçiniz'}
                  </b>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid container>
                    <List style={{ width: '100%' }}>
                      {staffs.map((staff) => (
                        <ListItem
                          button
                          key={staff.id}
                          onClick={() => {
                            setStaffAndGetHours(staff)
                          }}
                          disabled={!availableStaffs.includes(staff.id)}
                        >
                          <ListItemAvatar>
                            {staff.resim ? (
                              <Avatar src={staff.resimURL} />
                            ) : (
                              <Avatar
                                style={{
                                  color: '#fff',
                                  backgroundColor: '#2196f3'
                                }}
                              >
                                {staff.ad.substring(0, 1)}
                              </Avatar>
                            )}
                          </ListItemAvatar>
                          <ListItemText
                            secondary={
                              selectedSalon.salonTur ===
                              'Bay/Bayan Güzellik Salonu'
                                ? [
                                    'Kadın Kuaförü',
                                    'Erkek Kuaförü',
                                    'Kadın/Erkek Kuaförü'
                                  ][staff.cinsiyet]
                                : null
                            }
                          >
                            {staff.ad + '  ' + staff.soyad}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        {/*Saat Seç*/}
        <Accordion expanded={collapse === 'hours'}>
          <AccordionSummary onClick={() => goToCollapse('hours')}>
            <Typography>
              <b>
                Tarih/Saat Seçiniz (Çalışma Saatleri: {berberHours.basSaat} -{' '}
                {berberHours.bitSaat})
              </b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid container>{warning}</Grid>
              <Grid container>
                <ButtonGroup
                  disableElevation
                  color="primary"
                  fullWidth={true}
                  aria-label="large outlined primary button group"
                >
                  <Button
                    style={{ width: 80 }}
                    onClick={() => addTimeToSelectedDate(-1)}
                  >
                    <ArrowBackIosIcon />
                  </Button>
                  <Button>
                    <DatePicker
                      fullWidth
                      format="DD MMMM dddd"
                      value={selectedDate}
                      onChange={(...args) => {
                        //console.log('going brave 2')
                        setWarning('')
                        setTempSelectedDate(...args)
                      }}
                      minDate={moment().toDate()}
                    />
                  </Button>
                  <Button
                    style={{ width: 80 }}
                    onClick={() => addTimeToSelectedDate(1)}
                  >
                    <ArrowForwardIosIcon />
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid container>
                <List style={{ width: '100%' }}>
                  {availableHours.length > 0 ? (
                    availableHours.map((list, i) => (
                      <ListItem
                        button
                        key={'hour' + i}
                        style={{ flexWrap: 'wrap', justifyContent: 'center' }}
                      >
                        {list.map((availableHour, j) => {
                          return (
                            <Box
                              marginRight={1}
                              marginBottom={1}
                              key={'ahour' + j}
                            >
                              <Button
                                variant={'contained'}
                                disabled={!availableHour.status}
                                size="small"
                                style={{
                                  backgroundColor: availableHour.status
                                    ? '#4caf50'
                                    : '#f44336',

                                  color: 'white'
                                }}
                                onClick={() =>
                                  setSelectedHourAndShowSummary(
                                    availableHour.date
                                  )
                                }
                              >
                                {`${availableHour.date.format('HH:mm')}`}
                              </Button>
                            </Box>
                          )
                        })}
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemText primary={'Personel izin günü'} />
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid container justify={'space-between'}>
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => setCollapse('service')}
                >
                  Hizmet Seç
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/*Özet*/}
        <Accordion expanded={collapse === 'summary'}>
          <AccordionSummary onClick={() => goToCollapse('summary')}>
            <Typography>
              <b>Özet</b>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid container>
                <Typography>
                  <b>Seçilen Çalışan:</b>{' '}
                  {selectedStaff.ad + ' ' + selectedStaff.soyad} <br />
                  <b>Seçilen Hizmetler: </b>
                  {Object.values(selectedServices)
                    .map((service) => `${service.islemAdi}`)
                    .join(', ')}
                  <br />
                  <b>Seçilen Saat:</b>{' '}
                  {selectedHour.format('DD MMMM YYYY HH:mm')} <br />
                  <b>Toplam Süre:</b>{' '}
                  {_.sumBy(Object.values(selectedServices), (o) =>
                    parseInt(o.islemSuresi)
                  )}{' '}
                  dk <br />
                  <b>Toplam Ücret:</b>{' '}
                  {_.sumBy(Object.values(selectedServices), (o) =>
                    parseInt(o.islemUcreti)
                  )}{' '}
                  TL <br />
                </Typography>
              </Grid>
              <Grid
                container
                justify={'space-between'}
                style={{ marginTop: '24px' }}
              >
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => setCollapse('hours')}
                >
                  Tarih/Saat seç
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => approve()}
                >
                  Onayla
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default withContainer(Randevu)
