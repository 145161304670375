import React from 'react'
import Typography from '@material-ui/core/Typography'
import { DatePicker } from '@material-ui/pickers'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  List,
  ListItem,
  ListItemText,
  ButtonGroup,
  Button,
  Box,
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import moment from 'moment'

const SaatAccordion = ({
  // Collapse
  collapse,
  goToCollapse,
  setCollapse,

  berberHours,
  setTempSelectedDate,

  selectedStaff,
  selectedDate,

  availableHours,

  setSelectedHour,
  setAvailableHours,

  getHours,
}) => {
  const [warning, setWarning] = React.useState('')
  const [goingBack, setGoingBack] = React.useState(false)

  const setSelectedHourAndShowSummary = (hour) => {
    setSelectedHour(hour)
    setCollapse('summary')
  }

  const addTimeToSelectedDate = (val) => {
    if (val < 0) {
      setGoingBack(true)
    }
    setWarning('')
    setTempSelectedDate(selectedDate.clone().add(1 * val, 'day'))
  }

  React.useEffect(() => {
    let tArr = [
      'Pazartesi',
      'Salı',
      'Çarşamba',
      'Perşembe',
      'Cuma',
      'Cumartesi',
      'Pazar',
    ]

    if (selectedStaff.id) {
      const holiday = parseInt(selectedStaff.izin)
      const holidaySalon =
        parseInt(berberHours.bosGun) + 1 ? parseInt(berberHours.bosGun) : -1
      let todayCode = (parseInt(selectedDate.clone().day()) + 6) % 7

      if (todayCode === holiday) {
        setWarning(
          `${tArr[todayCode]} günü, personelin izin gününe geldiğinden tarih otomatik olarak değiştirildi.`
        )
        setTempSelectedDate(selectedDate.clone().add(goingBack ? -1 : 1, 'day'))
        setGoingBack(false)
      } else if (todayCode === holidaySalon) {
        setWarning(
          `${tArr[todayCode]} günü, salonun izin gününe geldiğinden tarih otomatik olarak değiştirildi.`
        )
        setTempSelectedDate(selectedDate.clone().add(goingBack ? -1 : 1, 'day'))
        setGoingBack(false)
      } else {
        let hours = getHours()

        // Eğer bugüne alınabilecek randevu yoksa, bir sonraki güne bak
        if (hours.length === 0) {
          setWarning(
            `${tArr[todayCode]} günü müsait randevu bulunmadığından tarih otomatik olarak değiştirildi.`
          )
          setTempSelectedDate(
            selectedDate.clone().add(goingBack ? -1 : 1, 'day')
          )
          setGoingBack(false)
        } else {
          setAvailableHours(hours)
        }
      }
    }
  }, [selectedDate])

  return (
    <Accordion expanded={collapse === 'hours'}>
      <AccordionSummary onClick={() => goToCollapse('hours')}>
        <Typography>
          <b>
            Tarih/Saat Seçiniz (Çalışma Saatleri: {berberHours.basSaat} -{' '}
            {berberHours.bitSaat})
          </b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container>{warning}</Grid>
          <Grid container>
            <ButtonGroup
              disableElevation
              color="primary"
              fullWidth={true}
              aria-label="large outlined primary button group"
            >
              <Button
                style={{ width: 80 }}
                onClick={() => addTimeToSelectedDate(-1)}
              >
                <ArrowBackIosIcon />
              </Button>
              <Button>
                <DatePicker
                  fullWidth
                  format="DD MMMM dddd"
                  value={selectedDate}
                  onChange={(...args) => {
                    //console.log('going brave 2')
                    setWarning('')
                    setTempSelectedDate(...args)
                  }}
                  minDate={moment().toDate()}
                />
              </Button>
              <Button
                style={{ width: 80 }}
                onClick={() => addTimeToSelectedDate(1)}
              >
                <ArrowForwardIosIcon />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid container>
            <List style={{ width: '100%' }}>
              {availableHours.length > 0 ? (
                availableHours.map((list, i) => (
                  <ListItem
                    button
                    key={'hour' + i}
                    style={{ flexWrap: 'wrap', justifyContent: 'center' }}
                  >
                    {list.map((availableHour, j) => {
                      return (
                        <Box marginRight={1} marginBottom={1} key={'aHour' + j}>
                          <Button
                            variant={'contained'}
                            disabled={!availableHour.status}
                            size="small"
                            style={{
                              backgroundColor: availableHour.status
                                ? '#4caf50'
                                : '#f44336',

                              color: 'white',
                            }}
                            onClick={() =>
                              setSelectedHourAndShowSummary(availableHour.date)
                            }
                          >
                            {`${availableHour.date.format('HH:mm')}`}
                          </Button>
                        </Box>
                      )
                    })}
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemText primary={'Personel izin günü'} />
                </ListItem>
              )}
            </List>
          </Grid>
          <Grid container justify={'space-between'}>
            <Button
              color={'primary'}
              variant={'outlined'}
              onClick={() => setCollapse('service')}
            >
              Hizmet Seç
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default SaatAccordion
