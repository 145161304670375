import moment from 'moment'

export function getYemekAsRandevu(selectedDate, start, end) {
  let yemek = []
  // yemek saatleri kontrolü
  if (start && end) {
    let date = {
      start: selectedDate
        .clone()
        .startOf('day')
        .add(
          moment(start, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
          'minutes'
        ),
      end: selectedDate
        .clone()
        .startOf('day')
        .add(
          moment(end, 'HH:mm').diff(moment().startOf('day'), 'minutes'),
          'minutes'
        ),
    }
    yemek.push({
      id: 'takvim' + date.start.unix() * 1000,
      start: date.start.startOf('minute'),
      end: date.end.startOf('minute'),
    })
  }
  return yemek
}

export function getRandevularAsStartEnd(app) {
  let randevular = []
  app.forEach((randevu) => {
    if (randevu.sure === 0) return
    randevular.push({
      id: randevu.id,
      start: moment(randevu.randevuTarih.seconds * 1000).startOf('minute'),
      end: moment(randevu.randevuTarih.seconds * 1000)
        .add(randevu.sure, 'minutes')
        .startOf('minute'),
    })
  })

  return randevular
}

export function getAvailableHours(
  selectedSalon,
  selectedServices,
  selectedDate,
  selectedStaff,
  startStr,
  endStr,
  randevular
) {
  let hours = []
  let durationAsUnix = 0

  let start = parseInt(startStr)
  let end = parseInt(endStr)
  let startMin = parseInt(startStr.split(':')[1])
  let endMin = parseInt(endStr.split(':')[1])

  // İşlem süresinin ne kadar uzun süreceğini hesapla
  Object.values(selectedServices).forEach((el) => {
    durationAsUnix += Number(el.islemSuresi) * 60
  })

  let durationAsMinutes = durationAsUnix / 60

  function getDateFromI(i) {
    // başlangıç ve bitiş tarihi arasında
    if (i < start * 60 || i >= (endMin ? end + 1 : end) * 60) {
      return { goOn: true }
    }

    let date = moment(selectedDate)
      .startOf('day')
      .add(i, 'minutes')
      .startOf('minutes')
    let status = true

    // eğer küsuratlı bir açılış saatine sahipse, saatin  o kısımlarını kırmızı yap
    if (i < start * 60 + startMin || i >= end * 60 + endMin) {
      return { status: false, date }
    }

    // şu andan önceki saatleri kırmızıya boya
    if (date.unix() < moment().unix()) {
      status = false
    }

    // son saatleri kırmızıya boya
    if (
      date.unix() >
      date
        .clone()
        .startOf('day')
        .add(end * 60 + endMin - durationAsMinutes, 'minutes')
        .unix()
    ) {
      //flex
      if (
        !selectedStaff.flex ||
        durationAsMinutes < 15 ||
        i + durationAsMinutes - 15 > end * 60 + endMin
      ) {
        status = false
      } else {
        // //  console.log('flex:', date)
      }
    }

    // randevularla çakışıyorsa kırmızıya boya
    randevular.forEach((randevu) => {
      if (
        date.unix() > randevu.start.unix() - durationAsUnix &&
        date.unix() < randevu.end.unix()
      ) {
        status = false
      }
    })

    return { date, status, goOn: false }
  }

  let userRequest
  if (selectedStaff && selectedStaff.aralik && selectedStaff.aralik !== '0') {
    userRequest = selectedStaff.aralik || '10'
  } else {
    userRequest = selectedSalon ? selectedSalon.aralik || '10' : '10'
  }
  let toN = Number(userRequest)
  if (isNaN(toN) || toN < 5 || toN > 60) toN = 5

  // Her dakika için uygunluğu kontrol et
  for (let i = 0; i < 24 * 60; i++) {
    // her 15 dakika için push
    let dividable = i % toN === 0

    if (dividable) {
      // console.log(
      //   'Dividable. If status false, check next N to find available slot'
      // )
      let { date, status, goOn } = getDateFromI(i)
      if (goOn) {
        continue
      }

      if (!status) {
        // console.log('check next N to find available slot')
        for (let z = 0; z < toN; z++) {
          let {
            date: nextAvailableDate,
            status: nextAvailableStatus,
            goOn: nextAvailableGoOn,
          } = getDateFromI(i + z)

          if (nextAvailableGoOn) {
            continue
          }

          if (nextAvailableStatus) {
            date = nextAvailableDate
            status = nextAvailableStatus
            break
          }
        }
      }

      let h = date.format('H')
      if (!hours[h]) {
        hours[h] = []
      }

      hours[h].push({
        status,
        date,
      })
    }
  }

  // tamamı kırmızı olan satırları temizle
  hours = hours.filter((hourRow) => !hourRow.every((hour) => !hour.status))

  return hours
}

export function getRandevuHours(
  selectedSalon,
  selectedServices,
  selectedDate,
  selectedStaff,
  berberHours,
  appointments
) {
  return getAvailableHours(
    selectedSalon,
    selectedServices,
    selectedDate,
    selectedStaff,
    selectedStaff.calisma || berberHours.basSaat,
    selectedStaff.calismaBitis || berberHours.bitSaat,
    [
      ...getRandevularAsStartEnd(appointments),
      ...getYemekAsRandevu(
        selectedDate,
        selectedStaff.yemek,
        selectedStaff.yemekBitis
      ),
    ]
  )
}
