import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Button,
} from '@material-ui/core'
import HizmetListItem from './HizmetListItem'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import WcIcon from '@material-ui/icons/Wc'
import moment from 'moment'

const HizmetAccordion = ({
  // Collapse
  collapse,
  goToCollapse,
  setCollapse,

  services,
  addRemoveService,
  setTempSelectedDate,

  selectedServices,
  randevuStyle,

  setServicesAndGetStaff,
}) => {
  const [openKadin, setOpenKadin] = React.useState(true)
  const [openErkek, setOpenErkek] = React.useState(true)

  const handleKadinClick = () => {
    setOpenKadin(!openKadin)
  }
  const handleErkekClick = () => {
    setOpenErkek(!openErkek)
  }

  const classes = makeStyles((theme) => ({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }))()

  const getTitle = () => {
    return Object.values(selectedServices).length > 0
      ? `Hizmet: ${Object.values(selectedServices)
          .map((el) => el.islemAdi)
          .join(', ')}`
      : 'Hizmet Seçiniz'
  }

  const getListCollapseHeader = (
    genderIdentifier,
    text,
    expandState,
    callback
  ) => {
    if (services.some((el) => el.cinsiyet === genderIdentifier)) {
      return (
        <ListItem button onClick={callback}>
          <ListItemIcon>
            <WcIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
          {expandState ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
      )
    }
    return ''
  }

  const getGenderBlock = (genderIdentifier, text, expandState, callback) => {
    return (
      <List style={{ width: '100%' }}>
        {getListCollapseHeader(genderIdentifier, text, expandState, callback)}
        <Collapse in={expandState} timeout="auto" unmountOnExit>
          <List
            button="true"
            className={classes.nested}
            component="div"
            disablePadding
          >
            {services
              .filter((el) => el.cinsiyet === genderIdentifier)
              .map((service) => (
                <HizmetListItem
                  key={service.id}
                  service={service}
                  addRemoveService={addRemoveService}
                  selectedServices={selectedServices}
                />
              ))}
          </List>
        </Collapse>
      </List>
    )
  }

  return (
    <Accordion expanded={collapse === 'service'}>
      <AccordionSummary onClick={() => goToCollapse('service')}>
        <Typography>
          <b>{getTitle()}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid container>
            {getGenderBlock(0, 'Kadın', openKadin, handleKadinClick)}
            {getGenderBlock(1, 'Erkek', openErkek, handleErkekClick)}
          </Grid>

          {randevuStyle === 'pht' ? (
            <Grid container justify={'space-between'}>
              <Button
                color={'primary'}
                variant={'outlined'}
                onClick={() => setCollapse('staff')}
              >
                Çalışan Seç
              </Button>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={() => {
                  setTempSelectedDate(moment())
                }}
                disabled={
                  Object.keys(selectedServices).filter(
                    (s) => selectedServices[s]
                  ).length === 0
                }
              >
                Tarih/Saat Seç
              </Button>
            </Grid>
          ) : (
            <Grid container justify={'space-between'}>
              <Button
                color={'primary'}
                variant={'contained'}
                onClick={() => {
                  //console.log('going brave')
                  setServicesAndGetStaff()
                }}
                disabled={
                  Object.keys(selectedServices).filter(
                    (s) => selectedServices[s]
                  ).length === 0
                }
              >
                Çalışan Seç
              </Button>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default HizmetAccordion
