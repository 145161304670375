import React from 'react'
import Grid from '@material-ui/core/Grid'
import withContainer from '../../HOCs/withContainer'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import moment from 'moment'
import Box from '@material-ui/core/Box'
import * as _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'

const Randevu = (props) => {
  const history = useHistory()
  const { kuaforId, userId } = useParams()
  const db = window.firebase.firestore().collection('kuaforumDB')
  const dbSalon = window.firebase.firestore().collection('Salonlar')

  const [collapse, setCollapse] = React.useState('staff')
  const [berberHours, setBerberHours] = React.useState({})
  const [staffs, setStaffs] = React.useState([])
  const [customerData, setCustomer] = React.useState({})
  const [services, setServices] = React.useState([])
  const [appointments, setAppointments] = React.useState([])
  const [selectedStaff, setSelectedStaff] = React.useState({})
  const [selectedDate, setSelectedDate] = React.useState(moment())
  const [tempSelectedDate, setTempSelectedDate] = React.useState(0)
  const [selectedHour, setSelectedHour] = React.useState(moment())
  const [availableHours, setAvailableHours] = React.useState([])
  const [selectedServices, setSelectedServices] = React.useState({})
  const [disableAuto, setDisableAuto] = React.useState(false)
  const [automaticHours, setAutomaticHours] = React.useState([])
  const [automaticAvailableHours, setAutomaticAvailableHours] = React.useState(
    []
  )
  const [selectedSalon, setSelectedSalon] = React.useState({})
  const [isFirstDay, setIsFirstDay] = React.useState(true)
  const [isApproved, setIsApproved] = React.useState(false)

  // App init
  React.useEffect(() => {
    init()
  }, [])

  function getYemekAsRandevu(internalSelectedDate) {
    let yemek = []
    //console.log('kac')
    // yemek saatleri kontrolü
    if (selectedStaff.yemek && selectedStaff.yemekBitis) {
      let date = {
        start: internalSelectedDate
          .clone()
          .startOf('day')
          .add(
            moment(selectedStaff.yemek, 'HH:mm').diff(
              moment().startOf('day'),
              'minutes'
            ),
            'minutes'
          ),
        end: internalSelectedDate
          .clone()
          .startOf('day')
          .add(
            moment(selectedStaff.yemekBitis, 'HH:mm').diff(
              moment().startOf('day'),
              'minutes'
            ),
            'minutes'
          )
      }
      //console.log('date', date)
      yemek.push({
        id: 'takvim' + date.start.unix() * 1000,
        start: date.start.startOf('minute'),
        end: date.end.startOf('minute')
        /*
        randevuTarih: {
          toMillis: () => {
            return Number(date.start)
          },
        },
        sure: moment(date.end).diff(moment(date.start)) / 60 / 1000,
        */
      })
    }
    return yemek
  }

  function getRandevularAsStartEnd(app) {
    let randevular = []
    app.forEach((randevu) => {
      if (randevu.sure === 0) {
        // //  console.log('dadi dadi sure 0')
        return
      }

      randevular.push({
        id: randevu.id,
        start: moment(randevu.randevuTarih.seconds * 1000).startOf('minute'),
        end: moment(randevu.randevuTarih.seconds * 1000)
          .add(randevu.sure, 'minutes')
          .startOf('minute')
      })
    })

    // //  console.log('Start-End Randevular')

    randevular.sort((a, b) => {
      return a.start.unix() - b.start.unix()
    })

    // randevular.forEach((el) => {
    //   // //  console.log(el.start.format('DD MMM HH:mm'), '-', el.end.format('HH:mm'))
    // })

    return randevular
  }

  function getAvailableHours(
    internalSelectedDate,
    startStr,
    endStr,
    randevular
  ) {
    // //  console.log('\n\n\n\n\n', internalSelectedDate, '\n\n\n\n\n')

    let hours = []
    let durationAsUnix = 0

    let start = parseInt(startStr)
    let end = parseInt(endStr)
    let startMin = parseInt(startStr.split(':')[1])
    let endMin = parseInt(endStr.split(':')[1])

    // İşlem süresinin ne kadar uzun süreceğini hesapla
    Object.values(selectedServices).forEach((el) => {
      durationAsUnix += Number(el.islemSuresi) * 60
    })

    let durationAsMinutes = durationAsUnix / 60

    //console.log('durationAsUnix', durationAsUnix)
    //console.log(start, end, randevular)

    function getDateFromI(i) {
      // başlangıç ve bitiş tarihi arasında
      if (i < start * 60 || i >= (endMin ? end + 1 : end) * 60) {
        return { goOn: true }
      }

      let date = moment(internalSelectedDate)
        .startOf('day')
        .add(i, 'minutes')
        .startOf('minutes')
      let status = true

      // eğer küsüratlı bir açılış saatine sahipse, saatin  o kısımlarını kırmızı yap
      if (i < start * 60 + startMin || i >= end * 60 + endMin) {
        return { status: false, date }
      }

      // şu andan önceki saatleri kırmızıya boya
      if (date.unix() < moment().unix()) {
        status = false
      }

      // son saatleri kırmızıya boya
      if (
        date.unix() >
        date
          .clone()
          .startOf('day')
          .add(end * 60 + endMin - durationAsMinutes, 'minutes')
          .unix()
      ) {
        //flex
        if (
          !selectedStaff.flex ||
          durationAsMinutes < 15 ||
          i + durationAsMinutes - 15 > end * 60 + endMin
        ) {
          status = false
        } else {
          // //  console.log('flex:', date)
        }
      }

      // randevularla çakışıyorsa kırmızıya boya
      randevular.forEach((randevu) => {
        if (
          date.unix() > randevu.start.unix() - durationAsUnix &&
          date.unix() < randevu.end.unix()
        ) {
          status = false
        }
      })

      return { date, status, goOn: false }
    }

    let kullaniciIstegi = '10'
    if (selectedStaff && selectedStaff.aralik && selectedStaff.aralik !== '0') {
      kullaniciIstegi = selectedStaff.aralik || '10'
    } else {
      kullaniciIstegi = selectedSalon ? selectedSalon.aralik || '10' : '10'
    }
    let toN = Number(kullaniciIstegi)
    if (isNaN(toN) || toN < 5 || toN > 60) toN = 5

    // Her dakika için uygunluğu kontrol et
    for (let i = 0; i < 24 * 60; i++) {
      // her 15 dakika için pushla
      if (i % toN === 0) {
        let { date, status, goOn } = getDateFromI(i)
        if (goOn) {
          continue
        }
        let h = date.format('H')
        if (!hours[h]) {
          hours[h] = []
        }

        /*
        if (!status) {
          for (let j = 1; j < 10; j++) {
            let newDate = getDateFromI(i - j)
            let newStatus = newDate.status
            if (newStatus) {
              date = newDate.date
              status = newDate.status
            // //  console.log('new date', j)
              break
            }
          }
        }
        */

        hours[h].push({
          status,
          date
        })
      }
    }

    // tamamı kırmızı olan satırları temizle
    hours = hours.filter((hourRow) => !hourRow.every((hour) => !hour.status))

    return hours
  }

  React.useEffect(() => {
    if (!disableAuto) {
      if (selectedStaff.id) {
        const holiday = parseInt(selectedStaff.izin)
        const holidaySalon =
          parseInt(berberHours.bosGun) + 1 ? parseInt(berberHours.bosGun) : -1
        //console.log('yemek', yemek)
        let todayCode = (parseInt(selectedDate.clone().day()) + 6) % 7
        if (todayCode === holiday) {
          setSelectedDate(selectedDate.clone().add(1, 'day'))
        } else if (todayCode === holidaySalon) {
          setSelectedDate(selectedDate.clone().add(1, 'day'))
        } else {
          let hours = getAvailableHours(
            selectedDate,
            selectedStaff.calisma || berberHours.basSaat,
            selectedStaff.calismaBitis || berberHours.bitSaat,
            [
              ...getRandevularAsStartEnd(appointments),
              ...getYemekAsRandevu(selectedDate)
            ]
          )

          hours.map((bolum) => {
            return bolum.map((el) => {
              // //  console.log(el.date.format('DD MMM HH:mm'), el.status)
            })
          })

          // Eğer bugüne alınabilecek randevu yoksa, bir sonraki güne bak
          if (hours.length === 0) {
            setSelectedDate(selectedDate.clone().add(1, 'day'))
          } else {
            setAutomaticHours([...automaticHours, hours])
            setAvailableHours(hours)
          }
        }
      }
      return () => {}
    }
  }, [selectedDate])

  React.useEffect(() => {
    if (tempSelectedDate !== 0) {
      // //  console.log('@@ appointment değişti')
      setSelectedDate(tempSelectedDate.clone())
    }
  }, [appointments])

  React.useEffect(() => {
    if (tempSelectedDate !== 0) {
      // //  console.log('@@ temp selected date degisti')
      getAppointments()
    }
  }, [tempSelectedDate])

  React.useEffect(() => {
    if (!disableAuto) {
      // //  console.log(customerData)

      if (staffs.length > 0 && Object.values(customerData).length > 0) {
        // //  console.log('AUTOMATING.............', customerData.lastAppointment)
        if (customerData.lastAppointment) {
          let findIt = staffs.find(
            (staff) => staff.id === customerData.lastAppointment.pID
          )
          if (findIt) {
            setStaffAndGetServices(findIt)
          } else {
            history.push(`/${kuaforId}/${userId}`)
          }
        } else {
          history.push(`/${kuaforId}/${userId}`)
        }
      }
    }
  }, [customerData, staffs, berberHours])

  React.useEffect(() => {
    if (!disableAuto) {
      // //  console.log('selectedServices', selectedServices)
      if (
        Object.values(selectedServices).length === 0 &&
        Object.values(customerData).length > 0
      ) {
        let ss = {}
        customerData.lastAppointment.hizmetler.forEach((hizmet) => {
          // //  console.log(hizmet)
          ss[hizmet] = services.find((el) => el.id === hizmet)
        })
        setSelectedServices(ss)
      }
    }
  }, [selectedServices])

  React.useEffect(() => {
    // //  console.log('availableHours degisti')
    props.setLoading(true)
    if (!disableAuto) {
      if (Object.values(selectedServices).length > 0) {
        // //  console.log('islem yapiyorum')
        // //  console.log('availableHours', availableHours)

        //setSelectedHourAndShowSummary(firstAvailableHour.date)
        if (isFirstDay) {
          // //  console.log('getting today')
          setIsFirstDay(false)
          setTempSelectedDate(selectedDate.clone())
        } else {
          // //  console.log('adding 1 day')
          setTempSelectedDate(selectedDate.clone().add(1, 'days'))
        }
      }
    }
  }, [availableHours, selectedServices])

  React.useEffect(() => {
    let dayCount = 2
    if (
      !disableAuto &&
      Object.keys(berberHours).length > 0 &&
      moment.duration(selectedDate.diff(moment())).asDays() > dayCount
    ) {
      // //  console.log('disabling auto')
      setDisableAuto(true)

      // //  console.log('123', automaticHours)
      //console.log('check if barber set', berberHours)
      //console.log('check automatic hours', automaticHours)
      // calculate first and last half of day

      var startTime = moment(berberHours.basSaat, 'HH:mm')
      var endTime = moment(berberHours.bitSaat, 'HH:mm')
      var duration = moment.duration(endTime.diff(startTime)).asMinutes()
      let tempHours = []

      let separators = [
        0,
        moment(berberHours.basSaat, 'HH:mm')
          .add(duration / 2, 'minutes')
          .format('HHmm'),
        moment(berberHours.basSaat, 'HH:mm')
          .add((duration * 3) / 4, 'minutes')
          .format('HHmm'),
        Infinity
      ]

      let [todayAutomaticHours, ...restAutomaticHours] = automaticHours

      todayAutomaticHours = todayAutomaticHours
        .flat()
        .filter((el) => el.status === true)

      if (
        todayAutomaticHours.length > 0 &&
        todayAutomaticHours[0].date
          .clone()
          .startOf('day')
          .diff(moment().startOf('day'), 'day') === 0
      ) {
        let todaysHours = []

        console.log(todayAutomaticHours)

        if (todayAutomaticHours.length < 3) {
          todaysHours = todayAutomaticHours
        } else {
          todaysHours.push(todayAutomaticHours[0])
          todaysHours.push(
            todayAutomaticHours[parseInt(todayAutomaticHours.length / 2)]
          )
          todaysHours.push(todayAutomaticHours[todayAutomaticHours.length - 1])
        }

        todaysHours.flat().forEach((el) => {
          tempHours.push({
            ...el,
            text: `${el.date.clone().format('D MMMM')}`
          })
        })
      } else {
        restAutomaticHours = [todayAutomaticHours, ...restAutomaticHours]
      }

      for (const searchOn of restAutomaticHours) {
        separators.forEach((now, j) => {
          if (j === 0) return
          let b4 = separators[j - 1]

          let secondAvailableHour = searchOn
            .flat()
            .find(
              (el) =>
                Number(el.date.clone().format('HHmm')) >= b4 &&
                Number(el.date.clone().format('HHmm')) < now &&
                el.status === true
            )

          if (secondAvailableHour)
            tempHours.push({
              ...secondAvailableHour,
              text: `${secondAvailableHour.date.clone().format('D MMMM')}`
            })
        })
      }

      // //  console.log(tempHours)

      // //  console.log('YALNIZCABİRKERE')
      setAutomaticAvailableHours([tempHours])
      setCollapse('hours')
      props.setLoading(false)
    }
  }, [automaticHours])

  const init = async () => {
    props.setLoading(true)
    await getBerber()
    await getSalon()
    await getStaffs()
    await getCustomer()
    props.setLoading(false)
  }

  const getDocumentsFromCollection = async (collection) => {
    const events = await window.firebase.firestore().collection(collection)
    return await events.get().then((querySnapshot) => {
      return querySnapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    })
  }

  const getBerber = async () => {
    const berber = await db
      .doc(kuaforId)
      .collection('Bilgiler')
      .doc('zaman')
      .get()
    if (!berber.exists) {
      history.push('/error/no-staff')
    }
    setBerberHours(berber.data())
  }

  const getSalon = async () => {
    const salon = await dbSalon.doc(kuaforId).get()
    if (!salon.exists) {
      history.push('/error/no-saloon')
    }

    if (
      !salon.data() ||
      !(salon.data().salonAktif && !salon.data().salonPasif)
    ) {
      history.push('/error/passive-saloon')
    } else {
      // //  console.log('PA', salon.data().salonPasif, salon.data().salonAktif)
      setSelectedSalon(salon.data())
    }
  }

  const getCustomer = async () => {
    const customer = await db
      .doc(kuaforId)
      .collection('Musteriler')
      .doc(userId)
      .get()
    if (!customer.exists) {
      history.push('/error/no-customer')
    }

    if (customer.data().isBanned) {
      history.push('/error/banned-customer')
    }

    let tempData = customer.data()

    setCustomer(tempData)
    return tempData
  }

  const getStaffs = async () => {
    const snapShot = await db.doc(kuaforId).collection('Personeller').get()
    let staffList = []
    snapShot.forEach((staff) => {
      if (!staff.data().deleted) {
        staffList.push({
          id: staff.id,
          ...staff.data()
          //resimURL: await getStaffImage(staff.data().resim),
        })
      }
    })
    // //  console.log('staffList', staffList)

    staffList.sort(function (a, b) {
      if (a.firstname < b.firstname) {
        return -1
      }
      if (a.firstname > b.firstname) {
        return 1
      }
      return 0
    })

    if (staffList && staffList.length === 0) {
      history.push('/error/no-staff')
    }

    let s = await Promise.all(
      staffList.map((staff) => {
        return new Promise((resolve, reject) => {
          window.firebase
            .storage()
            .ref(`/${kuaforId}/personeller/${staff.resim}`)
            .getDownloadURL()
            .then((url) => {
              resolve(url)
            })
            .catch((err) => {
              resolve('')
            })
        })
      })
    )

    staffList = staffList.map((el, i) => ({ ...el, resimURL: s[i] }))

    setStaffs(staffList)
    return staffList
  }

  const getIslemSuresi = (staff, service) => {
    if (typeof staff.hizmetler[service.id] == 'object') {
      if (staff.hizmetler[service.id][1] === -1) {
        return service.data().islemSuresi < 10 ? 10 : service.data().islemSuresi
      }
      return staff.hizmetler[service.id][1]
    }
    return service.data().islemSuresi
  }

  const getIslemUcreti = (staff, service) => {
    if (typeof staff.hizmetler[service.id] == 'object') {
      return staff.hizmetler[service.id][0] === -1
        ? service.data().islemUcreti
        : staff.hizmetler[service.id][0]
    }
    return staff.hizmetler[service.id] === -1
      ? service.data().islemUcreti
      : staff.hizmetler[service.id]
  }

  const setStaffAndGetServices = async (staff) => {
    props.setLoading(true)
    setSelectedStaff(staff)
    const snapShot = await db.doc(kuaforId).collection('Hizmetler').get()
    const serviceList = []
    snapShot.forEach((service) => {
      if (Object.keys(staff.hizmetler).includes(service.id)) {
        serviceList.push({
          id: service.id,
          ...service.data(),
          ...{
            islemSuresi: getIslemSuresi(staff, service),
            islemUcreti: getIslemUcreti(staff, service)
          }
        })
      }
    })
    setServices(serviceList)
    setCollapse('service')
    if (selectedStaff.id !== staff.id) {
      setSelectedServices({})
    }
    props.setLoading(false)
  }

  const getOnlyAppointments = async (callback) => {
    let thisMonth = tempSelectedDate.clone().startOf('month').unix()
    let nextMonth = tempSelectedDate
      .clone()
      .startOf('month')
      .add(1, 'month')
      .unix()

    let refConfirm = db.doc(kuaforId).collection('OnaylananRandevular')
    let refPending = db.doc(kuaforId).collection('BekleyenRandevular')

    let snapShotConfirmed = await refConfirm
      .doc(`${thisMonth}`)
      .collection('get')
      .get()

    let snapShotPending = await refPending
      .doc(`${thisMonth}`)
      .collection('get')
      .get()

    let snapShotConfirmedNextMonth = await refConfirm
      .doc(`${nextMonth}`)
      .collection('get')
      .get()

    let snapShotPendingNextMonth = await refPending
      .doc(`${nextMonth}`)
      .collection('get')
      .get()

    const snapShotRandevular = [
      ...snapShotConfirmed.docs,
      ...snapShotPending.docs,
      ...snapShotConfirmedNextMonth.docs,
      ...snapShotPendingNextMonth.docs
    ]
    //console.log(snapShotRandevular)

    const randevuList = []

    let personelTakvimi = await getDocumentsFromCollection(
      `personelDB/${selectedStaff.id}/Takvim`
    )

    // //  console.log('personelTakvimi', personelTakvimi)

    personelTakvimi.forEach((eventObj) => {
      eventObj.events.forEach((date) => {
        // //  console.log(date)
        randevuList.push({
          id: 'takvim' + date.start,
          randevuTarih: {
            toMillis: () => {
              // GMT + 3
              return Number(date.start - 60 * 60 * 3 * 1000)
            },
            seconds: Number(date.start - 60 * 60 * 3 * 1000) / 1000
          },
          sure: moment(date.end).diff(moment(date.start)) / 60 / 1000
        })
      })
    })

    snapShotRandevular.forEach((randevu) => {
      //console.log(randevu)

      //console.log('bbb', selectedStaff, randevu.data(), randevu.id)

      if (selectedStaff.id === randevu.data().pID) {
        //console.log({ id: randevu.id, ...randevu.data() })
        randevuList.push({ id: randevu.id, ...randevu.data() })
      }
    })

    // //  console.log('randevuList', randevuList)
    callback(randevuList)
  }

  const getAppointments = async () => {
    getOnlyAppointments((randevuList) => {
      setAppointments(randevuList)
    })
  }

  const setSelectedHourAndShowSummary = (hour) => {
    setSelectedHour(hour)
    setCollapse('summary')
  }

  const lastCheck = (callback) => {
    getOnlyAppointments((randevuList) => {
      let hours = getAvailableHours(
        selectedHour,
        selectedStaff.calisma || berberHours.basSaat,
        selectedStaff.calismaBitis || berberHours.bitSaat,
        [
          ...getRandevularAsStartEnd(randevuList),
          ...getYemekAsRandevu(selectedHour)
        ]
      )

      callback(
        hours
          .map((e) => e.filter((el) => el.status).map((v) => v.date))
          .flat()
          .some((e) => e.unix() === selectedHour.unix())
      )
    })
  }

  const approve = async () => {
    props.setLoading(true)
    if (isApproved) return
    setIsApproved(true)

    lastCheck(async (isValidDate) => {
      // //  console.log('isValidDate', isValidDate)

      if (!isValidDate) {
        alert(
          'Randevu tarihi artık geçerli değil. Lütfen geçerli bir randevu tarihi seçin.'
        )
        window.location.reload()
        props.setLoading(false)
        return
      }

      const currentUser = {
        uid: userId
      }
      const refConfirm = db.doc(kuaforId).collection('OnaylananRandevular')
      const refPending = db.doc(kuaforId).collection('BekleyenRandevular')

      const totalDuration = _.sumBy(Object.values(selectedServices), (o) =>
        parseInt(o.islemSuresi)
      )
      const totalPrice = _.sumBy(Object.values(selectedServices), (o) =>
        parseInt(o.islemUcreti)
      )

      let date = selectedHour.clone().startOf('month').unix()

      // //  console.log('Date', date, moment(date * 1000).format('DD MMM YYYY HH:mm'))

      // test if all is ok
      let testObject = {
        mID: currentUser.uid,
        mAd: `${customerData.firstname} ${customerData.lastname}`,
        mTel: customerData.phone,
        pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
        sAd: selectedSalon.salonAd,
        pID: selectedStaff.id,
        randevuTarih: selectedHour.toDate(),
        fiyat: totalPrice,
        sure: totalDuration,
        isWeb: false,
        isFast: true
      }

      if (
        !Object.values(testObject).every(
          (val) => val !== undefined && val !== null
        )
      ) {
        return false
      }

      if (selectedStaff.otomatikOnay) {
        //pasif:onaylananrandevular   diğeri: bekleyenrandevular   reddedilenrandevular
        // ootomatik onay varsa randevular(pasifRandevular)/ay başlangıcı/get/ranID
        //raporlar altında aynı yapı => count: randevu sayısı 1 arttır , income: randevu ücret ekle. Bu yalnızca otomatik onayda olacak.

        await refConfirm
          .doc(`${date}`)
          .collection('get')
          .doc()
          .set({
            hizmetler: Object.keys(selectedServices),
            favori: false,
            mID: currentUser.uid,
            mAd: `${customerData.firstname} ${customerData.lastname}`,
            mTel: customerData.phone,
            pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
            sAd: selectedSalon.salonAd,
            pID: selectedStaff.id,
            randevuTarih: selectedHour.toDate(),
            fiyat: totalPrice,
            sure: totalDuration,
            randevuRol: 'Musteri',
            randevuTur: 'Normal',
            onay: true,
            isWeb: false,
            isFast: true
          })
      } else {
        await refPending
          .doc(`${date}`)
          .collection('get')
          .doc()
          .set({
            hizmetler: Object.keys(selectedServices),
            favori: false,
            mID: currentUser.uid,
            mAd: `${customerData.firstname} ${customerData.lastname}`,
            mTel: customerData.phone,
            pAd: `${selectedStaff.ad} ${selectedStaff.soyad}`,
            sAd: selectedSalon.salonAd,
            pID: selectedStaff.id,
            randevuTarih: selectedHour.toDate(),
            fiyat: totalPrice,
            sure: totalDuration,
            randevuRol: 'Musteri',
            randevuTur: 'Normal',
            onay: false,
            isWeb: false,
            isFast: true
          })
      }

      const musteriSnapshot = await db
        .doc(kuaforId)
        .collection('Musteriler')
        .doc(currentUser.uid)
        .get()

      await db.doc(`${kuaforId}/Musteriler/${currentUser.uid}`).update({
        lastBookDate: selectedHour.toDate(),
        lastAppointment: {
          pID: selectedStaff.id,
          hizmetler: Object.keys(selectedServices).map((el) => Number(el))
        },
        penultimateAppointment: musteriSnapshot.data().lastAppointment
          ? musteriSnapshot.data().lastAppointment
          : {},
        penultimateBookDate: musteriSnapshot.data().lastBookDate
          ? musteriSnapshot.data().lastBookDate
          : {}
      })

      let sdate = selectedHour.unix()

      props.setLoading(false)
      if (selectedStaff.otomatikOnay) {
        window.location.href = '/success/approved/' + sdate
      } else {
        window.location.href = '/success/pending/' + sdate
      }
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {/*Hızlı Özet*/}

        <Accordion>
          <AccordionDetails>
            <Grid container>
              <Grid container>
                <List style={{ width: '50%' }}>
                  <ListItem>
                    {selectedStaff && selectedStaff.ad ? (
                      <ListItemAvatar>
                        {selectedStaff.resim ? (
                          <Avatar src={selectedStaff.resimURL} />
                        ) : (
                          <Avatar
                            style={{
                              color: '#fff',
                              backgroundColor: '#2196f3'
                            }}
                          >
                            {selectedStaff.ad.substring(0, 1)}
                          </Avatar>
                        )}
                      </ListItemAvatar>
                    ) : (
                      ''
                    )}

                    <ListItemText
                      primary={'Personel'}
                      secondary={
                        selectedStaff && !!Object.keys(selectedStaff).length
                          ? selectedStaff.ad + ' ' + selectedStaff.soyad
                          : 'Yükleniyor...'
                      }
                    />
                  </ListItem>
                </List>
                <List style={{ width: '50%' }}>
                  <ListItem>
                    <ListItemText
                      primary={'Hizmetler'}
                      secondary={
                        !!Object.values(selectedServices).length
                          ? Object.values(selectedServices)
                              .map((service) => `${service.islemAdi}`)
                              .join(', ')
                          : 'Yükleniyor...'
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/*Saat Seç*/}
        <Accordion expanded={collapse === 'hours'}>
          <AccordionSummary>
            <Typography>
              Tarih/Saat Seçiniz (Çalışma Saatleri: {berberHours.basSaat} -{' '}
              {berberHours.bitSaat})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid container>
                <List style={{ width: '100%' }}>
                  {automaticAvailableHours.flat().length > 0 ? (
                    automaticAvailableHours.map((list, i) =>
                      list.map((availableHour, i) => {
                        return (
                          <Box marginRight={1} marginBottom={1} key={'k' + i}>
                            {availableHour.status ? (
                              <ListItem
                                button
                                key={'hour' + i}
                                style={{ flexWrap: 'wrap' }}
                                onClick={() =>
                                  setSelectedHourAndShowSummary(
                                    availableHour.date
                                  )
                                }
                              >
                                <ListItemText>
                                  <b>
                                    {' '}
                                    {availableHour.text +
                                      ' ' +
                                      availableHour.date.format('HH:mm')}
                                  </b>
                                </ListItemText>
                              </ListItem>
                            ) : (
                              ''
                            )}
                          </Box>
                        )
                      })
                    )
                  ) : (
                    <ListItem>
                      <ListItemText
                        primary={
                          disableAuto
                            ? 'Sonraki 3 gün içerisinde alınabilecek otomatik randevu bulunmuyor.'
                            : ''
                        }
                      />
                    </ListItem>
                  )}
                </List>
              </Grid>
              <Grid container justify={'space-between'}>
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => history.push(`/${kuaforId}/${userId}`)}
                >
                  Farklı Randevu Seç
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/*Özet*/}
        <Accordion expanded={collapse === 'summary'}>
          <AccordionSummary>
            <Typography>Özet</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid container>
                <Typography>
                  <b>Seçilen Çalışan:</b>{' '}
                  {selectedStaff
                    ? selectedStaff.ad + ' ' + selectedStaff.soyad
                    : ''}{' '}
                  <br />
                  <b>Seçilen Hizmetler: </b>
                  {Object.values(selectedServices)
                    .map((service) => `${service.islemAdi}`)
                    .join(', ')}
                  <br />
                  <b>Seçilen Saat:</b>{' '}
                  {selectedHour.format('DD MMMM YYYY HH:mm')} <br />
                  <b>Toplam Süre:</b>{' '}
                  {_.sumBy(Object.values(selectedServices), (o) =>
                    parseInt(o.islemSuresi)
                  )}{' '}
                  dk <br />
                  <b>Toplam Ücret:</b>{' '}
                  {_.sumBy(Object.values(selectedServices), (o) =>
                    parseInt(o.islemUcreti)
                  )}{' '}
                  TL <br />
                </Typography>
              </Grid>
              <Grid
                container
                justify={'space-between'}
                style={{ marginTop: '24px' }}
              >
                <Button
                  color={'primary'}
                  variant={'outlined'}
                  onClick={() => setCollapse('hours')}
                >
                  Tarih/Saat seç
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => approve()}
                >
                  Onayla
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default withContainer(Randevu)
