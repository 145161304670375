import React from 'react'
import withContainer from '../../HOCs/withContainer'
import Typography from '@material-ui/core/Typography'
import Cancel from '@material-ui/icons/Cancel'
import Grid from '@material-ui/core/Grid'
import { useParams } from 'react-router-dom'

const ErrorPage = (props) => {
  const { type } = useParams()
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '90vh' }}
    >
      <Grid item>
        <Typography variant="h6" color="inherit" align="center">
          <Cancel
            style={{
              color: '#f44336',
              fontSize: 144
            }}
            // style={{ fontSize:'40px' }}
            fontSize="large"
          ></Cancel>
          <br></br>
          <div
            style={{
              color: '#444',
              fontSize: 20,
              fontWeight: 400
            }}
          >
            {type && type === 'no-customer'
              ? [
                  'Müşteri numarası hatalı veya eksik.',
                  <br />,
                  'Sorun devam ederse salondan çıkış yapıp, tekrar giriş yapabilirsiniz.'
                ]
              : type && type === 'banned-customer'
              ? [
                  'Salon yöneticisi, müşterinin bu salondan hizmet almasını engelledi.',
                  <br />,
                  'Bunun bir hata olduğunu düşünüyorsanız salon ile iletişime geçebilirsiniz.'
                ]
              : type && type === 'no-staff'
              ? [
                  'Salon yöneticisinin, personel tanımlaması beklenmektedir.',
                  <br />,
                  'Salon tarafından personel tanımlandıktan sonra, randevu alabilirsiniz.'
                ]
              : type && type === 'no-saloon'
              ? [
                  'Salon bulunamadı.',
                  <br />,
                  'Sorun devam ederse lütfen bizimle iletişime geçin.'
                ]
              : type && type === 'passive-saloon'
              ? [
                  'Salon pasif durumda.',
                  <br />,
                  'Seçmiş olduğunuz salon pasif durumda olduğundan randevu alınamaz.'
                ]
              : type && type === 'no-last'
              ? [
                  'Hızlı randevu başarısız.',
                  <br />,
                  'Daha önceden hiç randevu almadığınız için hızlı randevu alamazsınız.'
                ]
              : 'Bilinmeyen Hata.'}
          </div>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withContainer(ErrorPage)
