import React from 'react'

import Grid from '@material-ui/core/Grid'
import withContainer from '../../HOCs/withContainer'
import moment from 'moment'
import * as _ from 'lodash'
import { useParams, useHistory } from 'react-router-dom'

import PersonelAccordion from '../../Components/Randevu/PersonelAccordion'
import HizmetAccordion from '../../Components/Randevu/HizmetAccordion'
import SaatAccordion from '../../Components/Randevu/SaatAccordion'
import OzetAccordion from '../../Components/Randevu/OzetAccordion'

import { getRandevuHours } from '../../Helpers/RandevuHelpers'

const { useEffect, useState } = React

const Randevu = ({ setLoading }) => {
  const history = useHistory()
  const { salonId, userId, device } = useParams()
  const firestore = window.firebase.firestore()
  const db = firestore.collection('kuaforumDB')

  const [collapse, setRealCollapse] = useState()
  const [lastCollapse, setLastCollapse] = useState()
  const [berberHours, setBerberHours] = useState({})
  const [staffs, setStaffs] = useState([])
  const [customerData, setCustomer] = useState({})
  const [services, setServices] = useState([])
  const [appointments, setAppointments] = useState([])
  const [selectedStaff, setSelectedStaff] = useState({})
  const [selectedDate, setSelectedDate] = useState(moment())
  const [tempSelectedDate, setTempSelectedDate] = useState(0)
  const [selectedHour, setSelectedHour] = useState(moment())
  const [availableHours, setAvailableHours] = useState([])
  const [selectedServices, setSelectedServices] = useState({})
  const [disabledServices, setDisabledServices] = useState([])
  const [availableStaffs, setAvailableStaffs] = useState([])
  const [selectedSalon, setSelectedSalon] = useState({})
  const [randevuStyle, setRandevuStyle] = useState('pht')

  const getHours = (_appointments = appointments) => {
    return getRandevuHours(
      selectedSalon,
      selectedServices,
      selectedDate,
      selectedStaff,
      berberHours,
      _appointments
    )
  }

  const getBerber = async () => {
    const berber = await db
      .doc(salonId)
      .collection('Bilgiler')
      .doc('zaman')
      .get()
    if (!berber.exists) {
      history.push('/error/no-staff')
    }
    setBerberHours(berber.data())
  }

  const getSalon = async () => {
    const salon = await firestore.collection('Salonlar').doc(salonId).get()
    if (!salon.exists) {
      history.push('/error/no-saloon')
    }

    if (
      !salon.data() ||
      !(salon.data().salonAktif && !salon.data().salonPasif)
    ) {
      history.push('/error/passive-saloon')
    } else {
      setSelectedSalon(salon.data())
      setRandevuStyle(selectedSalon.randevuStili || 'pht')
    }
  }

  const getStaffs = async () => {
    const snapShot = await db.doc(salonId).collection('Personeller').get()
    let staffList = []
    snapShot.forEach((staff) => {
      if (!staff.data().deleted) {
        staffList.push({
          id: staff.id,
          ...staff.data()
        })
      }
    })

    staffList = staffList.map((el) => ({
      ...el,
      index: el.index < 0 || el.index === undefined ? 999 : el.index
    }))

    staffList
      .sort(function (a, b) {
        if (a.firstname < b.firstname) {
          return -1
        }
        if (a.firstname > b.firstname) {
          return 1
        }
        return 0
      })
      .sort((a, b) => a.index - b.index)

    if (staffList && staffList.length === 0) {
      history.push('/error/no-staff')
    }

    let s = await Promise.all(
      staffList.map((staff) => {
        return new Promise((resolve, reject) => {
          window.firebase
            .storage()
            .ref(`/${salonId}/personeller/${staff.resim}`)
            .getDownloadURL()
            .then((url) => {
              resolve(url)
            })
            .catch((err) => {
              resolve('')
            })
        })
      })
    )

    staffList = staffList.map((el, i) => ({ ...el, resimURL: s[i] }))

    setStaffs(staffList)

    if (staffList.length === 1 && randevuStyle === 'pht') {
      setStaffAndGetServices(staffList[0])
    }

    return staffList
  }

  const getCustomer = async () => {
    const customer = await db
      .doc(salonId)
      .collection('Musteriler')
      .doc(userId)
      .get()

    if (!customer.exists) {
      history.push('/error/no-customer')
    }

    setCustomer(customer.data())
  }

  const setServicesAndGetStaff = async () => {
    //setLoading(true)
    setAvailableStaffs(
      staffs
        .filter((staff) => {
          return Object.values(selectedServices).every((service) => {
            return Object.keys(staff.hizmetler).includes(String(service.id))
          })
        })
        .map((el) => el.id)
    )
    setCollapse('staff')
    //setLoading(false)
  }

  const setStaffAndGetHours = async (staff) => {
    setSelectedStaff(staff)
    setCollapse('hours')
    setTempSelectedDate(moment())
  }

  const calculateIslemSuresi = (staff, service, threshold) => {
    if (typeof staff.hizmetler[service.id] == 'object') {
      if (staff.hizmetler[service.id][1] === -1) {
        if (service.data().islemSuresi < threshold) {
          return threshold
        } else {
          return Number(service.data().islemSuresi)
        }
      } else {
        return Number(staff.hizmetler[service.id][1])
      }
    } else {
      return Number(service.data().islemSuresi)
    }
  }

  const calculateIslemUcreti = (staff, service) => {
    let serviceItem = staff.hizmetler[service.id]
    if (typeof serviceItem == 'object') serviceItem = serviceItem[0]
    return Number(serviceItem === -1 ? service.data().islemUcreti : serviceItem)
  }

  const setStaffAndGetServices = async (staff) => {
    //setLoading(true)
    setSelectedStaff(staff)
    const snapShot = await db.doc(salonId).collection('Hizmetler').get()
    const serviceList = []
    snapShot.forEach((service) => {
      if (Object.keys(staff.hizmetler).includes(service.id)) {
        serviceList.push({
          id: service.id,
          ...service.data(),
          ...{
            islemSuresi: calculateIslemSuresi(staff, service, 0),
            islemUcreti: calculateIslemUcreti(staff, service)
          }
        })
      }
    })

    let favoriteServices = serviceList.filter((el) => el.favorite)
    let notSoFavoriteServices = serviceList.filter((el) => !el.favorite)

    favoriteServices.sort(function (a, b) {
      return a.id - b.id
    })

    notSoFavoriteServices.sort(function (a, b) {
      return a.id - b.id
    })

    let rearrangedServiceList = [...favoriteServices, ...notSoFavoriteServices]

    // //  console.log('serviceList', serviceList, rearrangedServiceList)

    setServices(rearrangedServiceList)
    setCollapse('service')

    if (selectedStaff.id !== staff.id) {
      setSelectedServices({})
    }
    //setLoading(false)
  }

  const getOnlyAppointments = async (callback) => {
    const getDocumentsFromCollection = (collection) =>
      new Promise((resolve) =>
        window.firebase
          .firestore()
          .collection(collection)
          .get()
          .then((querySnapshot) => {
            resolve(
              querySnapshot.docs.map((doc) => {
                return { id: doc.id, ...doc.data() }
              })
            )
          })
      )

    let refConfirm = db.doc(salonId).collection('OnaylananRandevular')
    let refPending = db.doc(salonId).collection('BekleyenRandevular')

    let snapShotConfirmed = await refConfirm
      .doc(`${tempSelectedDate.clone().startOf('month').unix()}`)
      .collection('get')
      .get()

    let snapShotPending = await refPending
      .doc(`${tempSelectedDate.clone().startOf('month').unix()}`)
      .collection('get')
      .get()

    const snapShotRandevular = [
      ...snapShotConfirmed.docs,
      ...snapShotPending.docs
    ]

    const randevuList = []

    let personelTakvimi = await getDocumentsFromCollection(
      `personelDB/${selectedStaff.id}/Takvim`
    )

    console.log(personelTakvimi)

    personelTakvimi.forEach((eventObj) => {
      eventObj.events.forEach((date) => {
        //console.log(date)
        randevuList.push({
          id: 'takvim' + date.start,
          randevuTarih: {
            toMillis: () => {
              // GMT + 3
              return Number(date.start * 1000)
            },
            seconds: Math.floor(date.start / 1000) - 3 * 60 * 60
          },
          sure: moment(date.end).diff(moment(date.start)) / 60 / 1000
        })
      })
    })

    snapShotRandevular.forEach((randevu) => {
      //console.log(randevu)

      //console.log('bbb', selectedStaff, randevu.data(), randevu.id)

      if (selectedStaff.id === randevu.data().pID) {
        //console.log({ id: randevu.id, ...randevu.data() })
        randevuList.push({ id: randevu.id, ...randevu.data() })
      }
    })

    // //  console.log('randevuList', randevuList)
    callback(randevuList)
  }

  const getAppointments = () => {
    //setLoading(true)
    getOnlyAppointments((randevuList) => {
      setAppointments(randevuList)
      setCollapse('hours')
      //setLoading(false)
    })
  }

  // Sets last & current collapse
  const setCollapse = (val) => {
    setLastCollapse(val)
    setRealCollapse(val)
  }

  const goToCollapse = (val) => {
    console.log(val, lastCollapse, randevuStyle)
    let collapseOrder =
      randevuStyle === 'pht'
        ? ['staff', 'service', 'hours', 'summary']
        : ['service', 'staff', 'hours', 'summary']
    console.log(
      collapseOrder.findIndex((e) => e === lastCollapse),
      collapseOrder.findIndex((e) => e === val)
    )
    if (
      collapseOrder.findIndex((e) => e === lastCollapse) >=
      collapseOrder.findIndex((e) => e === val)
    ) {
      // //  console.log('last:', lastCollapse, 'now:', val)
      // only show, if something changes last collapse will change
      setRealCollapse(val)
    }
  }

  const addRemoveService = (sid) => {
    if (disabledServices.includes(sid)) {
      return
    }

    let _selectedServices = Object.assign({}, selectedServices)
    if (_selectedServices[sid]) {
      delete _selectedServices[sid]
    } else {
      const _service = _.findLast(services, { id: sid })
      _selectedServices[sid] = _service
    }

    setLastCollapse('service')

    let _services = staffs
      .filter((staff) => {
        return Object.values(_selectedServices).every((service) => {
          return Object.keys(staff.hizmetler).includes(String(service.id))
        })
      })
      .map((el) => el.hizmetler)

    let availableServices = Object.keys(Object.assign({}, ..._services)).map(
      Number
    )

    setDisabledServices(
      services.map((el) => el.id).filter((l) => !availableServices.includes(l))
    )

    setSelectedServices(_selectedServices)
  }

  const init = async () => {
    setLoading(true)
    await getBerber()
    await getSalon()
    getStaffs()
    getCustomer()
    setLoading(false)
  }

  // App init
  React.useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    if (tempSelectedDate !== 0) {
      setSelectedDate(tempSelectedDate.clone())
    }
  }, [appointments])

  useEffect(() => {
    if (tempSelectedDate !== 0) {
      getAppointments()
    }
  }, [tempSelectedDate])

  useEffect(() => {
    const getServicesFromAllStaff = async () => {
      const hizmetlerSnapshot = await db
        .doc(salonId)
        .collection('Hizmetler')
        .get()
      const serviceList = []
      hizmetlerSnapshot.forEach((service) => {
        staffs.forEach((staff) => {
          if (Object.keys(staff.hizmetler).includes(service.id)) {
            if (
              serviceList.find((oldService) => oldService.id === service.id)
            ) {
              console.warn('Service', service.id, 'already declared. Skipping.')
            } else {
              serviceList.push({
                id: service.id,
                ...service.data(),
                ...{
                  islemSuresi: calculateIslemSuresi(staff, service, 10),
                  islemUcreti: calculateIslemUcreti(staff, service)
                }
              })
            }
          }
        })
      })

      let favoriteServices = serviceList.filter((el) => el.favorite)

      let notSoFavoriteServices = serviceList.filter((el) => !el.favorite)

      favoriteServices.sort(function (a, b) {
        return a.id - b.id
      })

      notSoFavoriteServices.sort(function (a, b) {
        return a.id - b.id
      })

      let rearrangedServiceList = [
        ...favoriteServices,
        ...notSoFavoriteServices
      ]

      setServices(rearrangedServiceList)
      setCollapse('service')
    }

    if (selectedSalon && staffs.length > 0) {
      if (randevuStyle === 'pht') {
        setCollapse('staff')
      } else if (randevuStyle === 'hpt') {
        getServicesFromAllStaff()
      }
    }
  }, [randevuStyle, salonId, selectedSalon, staffs])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {randevuStyle === 'pht' && (
          <PersonelAccordion
            collapse={collapse}
            goToCollapse={goToCollapse}
            setStaffAndGetServices={setStaffAndGetServices}
            setStaffAndGetHours={setStaffAndGetHours}
            staffs={staffs}
            selectedSalon={selectedSalon}
            selectedStaff={selectedStaff}
            randevuStyle={randevuStyle}
          />
        )}
        {randevuStyle === 'pht' && (
          <HizmetAccordion
            collapse={collapse}
            goToCollapse={goToCollapse}
            services={services}
            addRemoveService={addRemoveService}
            setTempSelectedDate={setTempSelectedDate}
            selectedServices={selectedServices}
            setCollapse={setCollapse}
            randevuStyle={randevuStyle}
          />
        )}
        {randevuStyle === 'hpt' && (
          <HizmetAccordion
            collapse={collapse}
            goToCollapse={goToCollapse}
            services={services}
            addRemoveService={addRemoveService}
            setTempSelectedDate={setTempSelectedDate}
            selectedServices={selectedServices}
            setCollapse={setCollapse}
            randevuStyle={randevuStyle}
            setServicesAndGetStaff={setServicesAndGetStaff}
          />
        )}
        {randevuStyle === 'hpt' && (
          <PersonelAccordion
            collapse={collapse}
            goToCollapse={goToCollapse}
            setStaffAndGetServices={setStaffAndGetServices}
            setStaffAndGetHours={setStaffAndGetHours}
            staffs={staffs}
            selectedSalon={selectedSalon}
            selectedStaff={selectedStaff}
            availableStaffs={availableStaffs}
            randevuStyle={randevuStyle}
          />
        )}
        {/*Saat Seç*/}
        <SaatAccordion
          collapse={collapse}
          goToCollapse={goToCollapse}
          setCollapse={setCollapse}
          berberHours={berberHours}
          setTempSelectedDate={setTempSelectedDate}
          selectedStaff={selectedStaff}
          selectedDate={selectedDate}
          availableHours={availableHours}
          setSelectedHour={setSelectedHour}
          setAvailableHours={setAvailableHours}
          getHours={getHours}
        />
        {/*Özet*/}
        <OzetAccordion
          collapse={collapse}
          goToCollapse={goToCollapse}
          setCollapse={setCollapse}
          getOnlyAppointments={getOnlyAppointments}
          selectedDate={selectedDate}
          selectedHour={selectedHour}
          selectedServices={selectedServices}
          selectedSalon={selectedSalon}
          selectedStaff={selectedStaff}
          customerData={customerData}
          getAppointments={getAppointments}
          getHours={getHours}
          device={device}
          salonId={salonId}
          userId={userId}
        />
      </Grid>
    </Grid>
  )
}

export default withContainer(Randevu)
