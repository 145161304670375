import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routes from './Routes'
import { CssBaseline, ThemeProvider } from '@material-ui/core'
import DefaultTheme from './Themes/Default'
//import Header from "./Components/Header/Header";

function App() {
  const [currentUser, setCurrentUser] = React.useState(null)
  React.useEffect(() => {
    fakeLogin()
    const subscribe = window.firebase.auth().onAuthStateChanged((user) => {
      // console.log(user)
      setCurrentUser(user)
    })
    return () => {
      subscribe()
    }
  }, [])

  const fakeLogin = async () => {
    await window.firebase
      .auth()
      .signInWithEmailAndPassword('demo1@demo1.com', '123456')
  }

  return (
    <BrowserRouter>
      <ThemeProvider theme={DefaultTheme}>
        <CssBaseline />

        {currentUser ? <Routes /> : null}
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
